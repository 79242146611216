import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@fortawesome/free-solid-svg-icons'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  storelastsyncrecord,
  storesyncdataset
} from '../../../../services/data'

const SyncLogData = () => {
  const [storetimestamp, setstoretimestamp] = useState('')
  const store_id = sessionStorage.getItem('store_id')
  const getstore = localStorage.getItem('chatbot')

  // Function to fetch last sync timestamp
  const fetchDateTimestamp = async () => {
    if (!store_id || !getstore) return

    try {
      const res = await storelastsyncrecord(store_id, getstore)
      if (res?.data?.updatedAt) {
        setstoretimestamp(res.data.updatedAt)

        // Prevent duplicate toast notifications
        // toast.success(res.message, {
        //   position: 'bottom-center',
        //   toastId: 'syncToast1' // Unique toast ID to prevent duplicates
        // })
      }
    } catch (error) {
      console.error('Error fetching sync data:', error)
    }
  }

  // Fetch last sync on component mount
  useEffect(() => {
    fetchDateTimestamp()
  }, [])

  // Function to manually trigger sync
  const handleSyncData = async () => {
    try {
      const response = await storesyncdataset(store_id, getstore)
      console.log('Sync Response:', response)
      if (response.success) {
        const res = await storelastsyncrecord(store_id, getstore)
        setstoretimestamp(res.data.updatedAt)
        toast.success(response.message, {
          position: 'bottom-center',
        })
        // window.dispatchEvent(new CustomEvent('listmodalclose', { detail: true }))
        // setstoretimestamp(response.data.updatedAt)
        // fetchDateTimestamp()
        // Prevent duplicate toast notifications
      }
    } catch (error) {
      console.error('Error syncing data:', error)
    }
  }

  return (
    <div className='flex-grow'>
      <div className='mt-4  '>
        <div className='flex gap-2 mt-2'>
          <p className='font-[600] text-[13.5px] text-[#252C32]'>
            Last Sync:
          </p>
          <span className='font-[500] text-[13.5px] text-[#252C32]'>
            {storetimestamp
              ? new Date(storetimestamp).toLocaleString('en-US', {
                  timeZone: 'UTC'
                })
              : 'No sync data available'}
          </span>
        </div>
      </div>
      <div className='mt-11 md:w-[120px]'>
        <button
          onClick={handleSyncData} // Trigger sync on click
          className='mt-4 md:mt-0 px-4 py-3 bg-[#000000] w-full md:w-[140px] flex justify-center items-center text-white rounded-full text-[14px]'
        >
          <p className='text-[11px] mr-2 font-[700]'>
            <FontAwesomeIcon icon={faSync} />
          </p>
          <p className='mr-2 text-[12px] font-[700]'> Sync Now </p>
        </button>
        </div>
    </div>
  )
}

export default SyncLogData
