import { useEffect,useState } from "react";

const FilterDropDownHook =  () =>{

    const [isopenpopup, setisopenpopup] = useState(false)

    const handleopenClosedropdown = () =>{
        if(!isopenpopup){
            setisopenpopup(true)
        }else{
           setisopenpopup(false)
        }
    }

    return {handleopenClosedropdown,isopenpopup}

}


export default FilterDropDownHook;