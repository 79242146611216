const TrainFilterDropdownPopup = ({
  isopenpopup,
  clearbtn,
  fields = [], // Custom fields prop
  onsubmit
}) => {
  const handleclose = () => {
    isopenpopup()
  }

  return (
    <div
      className={`h-auto absolute mt-3 right-0 top-8 w-auto min-w-[350px] rounded-[6px] 
      bg-white shadow-[0px_0px_5px_rgba(0,0,0,0.2)] z-[100] transition-all duration-300 transform`}
    >
      {/* Header */}
      <div className='pt-4 pb-4 px-7 w-full flex justify-between items-center border-b-[1px] border-[#DFE5F1]'>
        <p className='text-[18px] font-semibold'>Filter</p>
        <p onClick={clearbtn} className='text-[14px] cursor-pointer pt-[1.5px] font-medium text-[#6E58F1]'>
          Clear All
        </p>
      </div>
      <form  onSubmit={(e) => {
          e.preventDefault(); // Prevent default form submission
          onsubmit(e); // Call parent submit function
        }}  
        className="w-full">
        {/* Custom Fields */}
        <div className='pt-6 pb-6 px-7 w-full border-b-[1px] flex flex-col gap-6 border-[#DFE5F1]'>
          {fields.map((field, index) => (
            <div key={index} className='relative w-full'>
              {field}
            </div>
          ))}
        </div>

        {/* Bottom Buttons */}
        <div className='pt-4 pb-4 px-7 w-full flex gap-2 justify-end items-center border-b-[1px] border-[#DFE5F1]'>
          <button
            onClick={handleclose}
            className='bg-[#E0E0E0] text-black px-[30px] h-[32px] rounded-[6px] font-normal text-[12px]'
          >
            Cancel
          </button>
          <button type="submit" className='bg-[#7C50DD] text-white px-[30px] h-[32px] rounded-[6px] font-normal text-[12px]'>
            Apply
          </button>
        </div>
      </form>
    </div>
  )
}

export default TrainFilterDropdownPopup
