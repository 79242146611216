import { useEffect, useState, useRef, useCallback } from 'react'
import { FiTrash, FiPlus } from 'react-icons/fi'
import '../../../styles/convoflow.css'
import { v4 as uuidv4 } from 'uuid'
import IonIcon from '@reacticons/ionicons'
import {
  deleteconvoflowbyid,
  getconvoflow,
  postconvoflow,
  updateconvoflow
} from '../../../services/data'
import { toast, ToastContainer } from 'react-toastify'
import { set } from 'date-fns'

const ConvoFlow = () => {
  var option_id
  const [openConvoModule, SetopenConvoModule] = useState(false)
  const [storeLabel, SetstoreLabel] = useState('')
  const [storeConvoId, SetstoreConvoId] = useState('')
  const [storeblockObject, SetstoreblockObject] = useState([]) // Initially an empty array
  const [connectionArray, SetConnectionArray] = useState({})
  const [showconvoelement, Setconvoelement] = useState(false)
  const [showconnectionelement, Setshowconnectionelement] = useState(false)
  const [showoptionelement, Setshowoptionelement] = useState(false)
  const [options, setoptions] = useState(false)
  const [currentblockId, SetcurrentblockId] = useState('')
  const [currentoptionId, SetcurrentoptionId] = useState('')
  const [newblockID, SetnewblockID] = useState('')
  const [value, Setvalue] = useState('')
  const [inputValues, setInputValues] = useState({})
  const [activeInputId, setActiveInputId] = useState(null)
  const [callconnection, setcallconnection] = useState(false)
  const [finalarray, setfinalarray] = useState({})
  const [getconvolist, setgetconvolist] = useState([])
  const [visibleItems, setVisibleItems] = useState(4)
  const [convoloading, setconvoLoading] = useState(false)
  const [page, setPage] = useState(1) // Track current page
  const [totalPages, setTotalPages] = useState(1) // Track total pages
  const [showupdateconvomodule, setshowupdateconvomodule] = useState(false)
  const [storeconvoid, setstoreconvoid] = useState('')
  const [storeconvoposition, setstoreconvoposition] = useState('')
  const [storeconfigid, setstoreconfigid] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [showbranch, setshowbranch] = useState(false)

  // useEffect(() => {
  //   console.log(storeConvoId)
  // }, [storeConvoId])

  const inputRefs = useRef({})
  const observer = useRef(null)

  const Getconvoflowlist = async (pages = [], direction = 'down') => {
    if (observer.current || !sessionStorage.getItem('store_id')) return

    observer.current = true // Mark request as in-progress

    try {
      const storeId = sessionStorage.getItem('store_id')
      const responses = await Promise.all(
        pages.map(p => getconvoflow(storeId, '', p))
      )

      const newData = responses.flatMap(res => res?.data || [])
      setTotalPages(
        Math.ceil(
          responses[0]?.pagination.total / responses[0]?.pagination.limit
        )
      )

      setgetconvolist(prev => {
        if (pages.includes(1)) {
          return newData // Reset on first load
        } else if (direction === 'up') {
          return [...newData, ...prev] // Prepend older messages
        } else {
          return [...prev, ...newData] // Append newer messages
        }
      })
    } finally {
      observer.current = false // Allow new API calls
    }
  }

  // Fetch data when `page` changes
  useEffect(() => {
    Getconvoflowlist([page])
  }, [page])

  const handleScroll = useCallback(
    e => {
      if (observer.current) return // Prevent multiple calls

      const { scrollTop, scrollHeight, clientHeight } = e.target

      if (scrollTop === 0 && page > 2) {
        setPage(prev => {
          const newPages = [prev - 1, prev - 2].filter(p => p > 0) // Ensure no negative pages
          Getconvoflowlist(newPages, 'up')
          return prev - 2
        })
      } else if (scrollTop === 0 && page === 2) {
        setPage(prev => {
          Getconvoflowlist([1], 'up')
          return 1
        })
      }

      if (
        scrollTop + clientHeight >= scrollHeight - 10 &&
        page < totalPages - 1
      ) {
        setPage(prev => {
          const newPages = [prev + 1, prev + 2].filter(p => p <= totalPages)
          Getconvoflowlist(newPages, 'down')
          return prev + 2
        })
      } else if (
        scrollTop + clientHeight >= scrollHeight - 10 &&
        page === totalPages - 1
      ) {
        setPage(prev => {
          Getconvoflowlist([totalPages], 'down')
          return totalPages
        })
      }
    },
    [page, totalPages]
  )

  // Log updates when page changes
  useEffect(() => {
    console.log('page', page)
  }, [page])

  // Update the text for the block
  const updateItemText = (blockId, newText) => {
    const updatedStore = storeblockObject.map(item => {
      if (item.id === blockId) {
        return { ...item, data: { ...item.data, text: newText } }
      }
      return item
    })

    SetstoreblockObject(updatedStore)
  }

  // Update the text for the option
  const updateOptionText = (blockId, optionId, newText) => {
    const updatedStore = storeblockObject.map(item => {
      if (item.id === blockId) {
        const updatedOptions = item.data.options.map(option => {
          if (option.id === optionId) {
            return { ...option, text: newText }
          }
          return option
        })
        return { ...item, data: { ...item.data, options: updatedOptions } }
      }
      return item
    })
    SetstoreblockObject(updatedStore)
  }

  // Update the name for the option
  const updateOptionName = (blockId, optionId, newName) => {
    const updatedStore = storeblockObject.map(item => {
      if (item.id === blockId) {
        const updatedOptions = item.data.options.map(option => {
          if (option.id === optionId) {
            return { ...option, text: newName }
          }
          return option
        })
        return { ...item, data: { ...item.data, options: updatedOptions } }
      }
      return item
    })
    SetstoreblockObject(updatedStore)
  }

  // Update the URL for the option
  const updateOptionUrl = (blockId, optionId, newUrl) => {
    const updatedStore = storeblockObject.map(item => {
      if (item.id === blockId) {
        const updatedOptions = item.data.options.map(option => {
          if (option.id === optionId) {
            return { ...option, url: newUrl }
          }
          return option
        })
        return { ...item, data: { ...item.data, options: updatedOptions } }
      }
      return item
    })
    SetstoreblockObject(updatedStore)
  }

  const handlecreateblock = (label, type, optionId = false) => {
    // const newBlockId = uuidv4() // Generate a unique ID for each block
    let newBlock = {}
    // console.log(newBlockId)
    // If label is 'TEXT_OPTIONS', we just set the options flag to true
    if (label === 'TEXT_OPTIONS') {
      setoptions(true)
    } else {
      // If it's BUTTON or LINK, create the block with the specified type
      if (type === 'BUTTON' || type === 'LINK') {
        newBlock = {
          id: uuidv4(),
          type: type === 'BUTTON' || 'LINK' ? 'TEXT_OPTIONS' : 'TEXT',
          data: {
            text: '',
            options: [
              {
                id: uuidv4(),
                type: type,
                text: '',
                ...(type === 'LINK' ? { url: '' } : {})
              }
            ] // Initializing empty array for options
          }
        }
        setoptions(false)
        Setconvoelement(false)
        Setshowconnectionelement(false)
        // SetnewblockID(newBlock.id)
        // console.log('New Block ID', newBlock.id)
        console.log("check connextion", callconnection, optionId)

        if (callconnection && optionId) {
          handleUpdateConnection(newBlock.id, optionId)
          // window.dispatchEvent(
          //   new CustomEvent('handletriggerconnection', {
          //     detail: { success: true, newblockid: newBlock.id }
          //   })
          // )
        } else {
          console.log('callconnection is false hi')
        }
      } else {
        newBlock = {
          id: uuidv4(),
          type: type,
          data: {
            text: ''
          }
        }
        setoptions(false)
        Setconvoelement(false)
        Setshowconnectionelement(false)
        // SetnewblockID(newBlock.id)

        if (callconnection && optionId) {
          handleUpdateConnection(newBlock.id, optionId)
          // window.dispatchEvent(
          //   new CustomEvent('handletriggerconnection', {
          //     detail: { success: true, newblockid: newBlock.id }
          //   })
          // )
        }
        console.log('callconnection is false')
      }

      // Add the new block to the store
      SetstoreblockObject(prev => [...prev, newBlock])

      SetnewblockID(newBlock.id) // Update the state with the new block ID
    }
  }

  const handleinsertoption = (blockid, optionid, elementtype) => {
    SetstoreblockObject(prevBlocks => {
      // Find the block with the matching id
      const blockToUpdate = prevBlocks.find(block => block.id === blockid)
      if (blockToUpdate) {
        // Create a new option object
        const newOption = {
          id: uuidv4(),
          type: elementtype,
          text: '',
          ...(elementtype === 'LINK' ? { url: '' } : {})
        }

        // Append the new option to the block's options
        const updatedBlock = {
          ...blockToUpdate,
          data: {
            ...blockToUpdate.data,
            options: [...blockToUpdate.data.options, newOption]
          }
        }
        Setshowoptionelement(false)
        // Replace the block in the array while keeping other blocks unchanged
        return prevBlocks.map(block =>
          block.id === blockid ? updatedBlock : block
        )
      }

      // If block not found, return the original array
      return prevBlocks
    })
  }

  const handledeleteoption = (blockId, optionId) => {
    let toDelete = new Set();
    var blocks = [...storeblockObject];

    function findAndDelete(id) {
      let block = blocks.find(b => b.id === id);
      if (!block) return;

      // delete connected block
      if (block.type === "TEXT_OPTIONS" && block.data.options) {
          block.data.options.forEach(option => {
              if (option.type === "BUTTON" && connectionArray[option.id]) {
                let next_block_id = connectionArray[option.id][0]; 
                delete connectionArray[option.id];
                findAndDelete(next_block_id); // Recursive delete
              }
          });
      }

      // delete current block
      toDelete.add(block.id);
    }


    // Start
    if(connectionArray[optionId]){
      let connected_blockId = connectionArray[optionId][0]; 
      delete connectionArray[optionId];
      findAndDelete(connected_blockId);
    }


    // Filter out deleted chain blocks
    console.log("delete",toDelete);  
    console.log("old flow", blocks);

    blocks = blocks.filter(b => !toDelete.has(b.id));
    // delete self options
    blocks = blocks.map(b => {
      if (b.id === blockId) {
        return {
          ...b,
          data: {
            ...b.data,
            options: b.data.options.filter(opt => opt.id !== optionId)
          }
        }
      }
      return b
    });
    console.log("new flow", blocks);
    console.log("new connectionArray", connectionArray);
    
    SetstoreblockObject(blocks);
    SetConnectionArray(connectionArray);
    // Assuming storeblockObject is your state variable for the data structure
    // SetstoreblockObject(prevBlocks => {
    //   return prevBlocks.map(block => {
    //     if (block.id === blockId) {
    //       // Filter out the option with the matching id
    //       return {
    //         ...block,
    //         data: {
    //           ...block.data,
    //           options: block.data.options.filter(opt => opt.id !== optionId)
    //         }
    //       }
    //     }
    //     return block // Return block unchanged if it doesn't match
    //   })
    // })
  }

  const handledeleteblock = blockId => {
    // Filter out the block with the matching id
    console.log(blockId)
    // window.dispatchEvent(
    //   new CustomEvent('removerelatedconnection', {
    //     detail: { message: true, id: blockId }
    //   })
    // )
    SetstoreblockObject(prevBlocks =>
      prevBlocks.filter(block => block.id !== blockId)
    )

    delete connectionArray[blockId];
    SetConnectionArray(connectionArray);
  }

  const handleUpdateConnection = (newBlockId, optionId) => {
    SetConnectionArray(prevState => {
        return {
          ...prevState,
          [optionId]: [newBlockId]
        };
    });
  };


  const handleconnection = optionId => {
    // Ignore if optionId is null or undefined
    if (!optionId) return;

    const handleTriggerConnection = event => {
        if (event.detail?.success) {
            const newBlockId = event.detail.newblockid;
            console.log("New Block ID:", newBlockId);
            console.log("Option ID:", optionId);

            SetConnectionArray(prevState => {
                // Ensure prevState is an object
                const safePrevState = typeof prevState === "object" && prevState !== null ? prevState : {};

                // Ensure `connectionArray` is an array
                const safeConnectionArray = Array.isArray(connectionArray) ? connectionArray : [];

                // 🔹 Check if `optionId` already exists in mapping
                if (safePrevState[optionId]) {
                    console.log(`Existing mapping found for ${optionId}:`, safePrevState[optionId]);

                    // 🔹 Iterate through `safeConnectionArray`
                    // for (let object of safeConnectionArray) {
                    //     if (object?.op) {
                    //         console.log(`Found object with op:`, object);

                    //         //Overwrite mapping if condition matches
                    //         return {
                    //             ...safePrevState,
                    //             [optionId]: [newBlockId]
                    //         };
                    //     }
                    // }

                    // If no condition matches, return the existing state
                    return {
                      ...safePrevState,
                      [optionId]: [newBlockId]
                    };
                }

                // If `optionId` does not exist, create a new mapping
                console.log(`No existing mapping for ${optionId}, creating a new one.`);
                return {
                    ...safePrevState,
                    [optionId]: [newBlockId]
                };
            });

            setcallconnection(false);
        }
    };

    // Prevent duplicate event listeners
    window.removeEventListener("handletriggerconnection", handleTriggerConnection);
    window.addEventListener("handletriggerconnection", handleTriggerConnection);
};

  

  useEffect(() => {
    console.log(connectionArray)
    console.log(storeblockObject)
  }, [connectionArray, storeblockObject])

  // useEffect(()=>{
  //   console.log(finalarray)
  // },[finalarray])

  const validateFields = () => {
    // Ensure required fields are not empty
    if (!storeLabel.trim()) {
      toast.error('Label cannot be empty', {
        autoClose: 3000,
        position: 'bottom-center'
      })
      return false;
    }

    if (!storeblockObject || storeblockObject.length === 0) {
      toast.error('Blocks cannot be empty', {
        autoClose: 3000,
        position: 'bottom-center'
      })
      return false;
    }

    // Validate if all required fields inside `storeblockObject` are filled
    const hasError = storeblockObject.some((block) => {
      if (block.type === 'TEXT_OPTIONS') {
        return block.data.options.some((option) => option.text.trim() === '');
      }
    });

    if (hasError) {
      toast.error('All fields inside blocks must be filled', {
        autoClose: 3000,
        position: 'bottom-center'
      })
      return false; 
    }

    return true;
  }

  const postconvoflowarray = async () => {
    
    if (!validateFields()) return;

    const connection = Object.fromEntries(
      Object.entries(connectionArray).map(([key, value]) => [
        key,
        Array.isArray(value) ? value[0] : value
      ])
    )

    const newFinalArray = {
      convo_id: uuidv4(),
      label: storeLabel, // Ensure this is the latest value
      connection: connection,
      blocks: storeblockObject
    }

    // Set state asynchronously but don't rely on it immediately
    setfinalarray(newFinalArray)

    if (sessionStorage.getItem('store_id')) {
      const response = await postconvoflow(
        sessionStorage.getItem('store_id'),
        newFinalArray // Pass the correct updated object directly
      )

      console.log(response)

      if (response?.success) {
        toast.success(response.message, {
          autoClose: 3000,
          position: 'bottom-center'
        })
        SetopenConvoModule(false)

        const convoResponse = await getconvoflow(
          sessionStorage.getItem('store_id'),
          '',
          ''
        )

        if (convoResponse?.data) {
          setgetconvolist(convoResponse.data)
        } else {
          setgetconvolist([]) // Ensure it's an empty array if the response is invalid
        }

        // Reset state after successful submission
        SetstoreblockObject([])
        SetConnectionArray({})
        SetstoreLabel('')
      } else {
        toast.error('Failed to save conversation', {
          autoClose: 3000,
          position: 'bottom-center'
        })
      }
    }
  }

  const updateconvoflowarray = async () => {

    if (!validateFields()) return;

    const connection = Object.fromEntries(
      Object.entries(connectionArray).map(([key, value]) => [
        key,
        Array.isArray(value) ? value[0] : value
      ])
    )

    const newupdatedFinalArray = {
      convo_id: storeconfigid,
      label: storeLabel, // Ensure this is the latest value
      connection: connection,
      blocks: storeblockObject
    }

    // Instead of waiting for state update, use newupdatedFinalArray directly
    const res = await updateconvoflow(
      storeconvoid,
      storeconvoposition,
      newupdatedFinalArray
    )

    console.log(res)

    if (res.success) {
      toast.success(res.message, {
        autoClose: 3000,
        position: 'bottom-center'
      })

      SetopenConvoModule(false)
      const convoResponse = await getconvoflow(
        sessionStorage.getItem('store_id'),
        '',
        ''
      )

      if (convoResponse?.data) {
        setgetconvolist(convoResponse.data)
      } else {
        setgetconvolist([]) // Ensure it's an empty array if the response is invalid
      }
      SetstoreblockObject([]) // Reset states
      SetConnectionArray({})
      SetstoreLabel('')
    }
  }

  const deleteconvoflow = async () => {
    console.log('Deleting convo with ID:', storeconvoid)

    try {
      if (!storeconvoid) {
        console.warn('No convo ID available for deletion')
        return
      }

      // Attempt to delete convo
      const deleteResponse = await deleteconvoflowbyid(storeconvoid)

      if (deleteResponse.success) {
        toast.success(deleteResponse.message, {
          autoClose: 3000,
          position: 'bottom-center'
        })

        SetopenConvoModule(false)

        // Fetch the updated convo list
        const convoResponse = await getconvoflow(
          sessionStorage.getItem('store_id'),
          '',
          ''
        )

        if (convoResponse?.data) {
          setgetconvolist(convoResponse.data)
        } else {
          setgetconvolist([]) // Ensure it's an empty array if the response is invalid
        }

        // Reset states
        SetstoreblockObject([])
        SetConnectionArray({})
        SetstoreLabel('')
      } else {
        console.warn('Failed to delete convo:', deleteResponse.message)
      }
    } catch (error) {
      console.error('Error deleting convo:', error)
    }
  }

  useEffect(() => {
    console.log(finalarray)
  }, [finalarray])

  const handleupdateOptionTextSubmit = (e, blockId, optionId) => {
    e.preventDefault() // Prevent page reload
    // console.log(e.target)
    const formData = new FormData(e.target) //  Get form values
    console.log('FormData Entries:', [...formData.entries()]) // Debug: Log all form values

    const updatedValue = formData.get('optionText') // Get input value
    console.log('Updated Value:', updatedValue) // Debugging

    if (updatedValue) {
      updateOptionText(blockId, optionId, updatedValue) // Update with new value
    } else {
      console.error('Input value is EMPTY!')
    }
  }

  const handleupdatedOptionNameTextSubmit = (e, blockId, optionId) => {
    e.preventDefault() // Prevent page reload
    // console.log(e.target)
    const formData = new FormData(e.target) // Get form values
    console.log('FormData Entries:', [...formData.entries()]) // Debug: Log all form values

    const updatedValue = formData.get('updatedOptionName') // Get input value
    console.log('Updated Value:', updatedValue) // Debugging

    if (updatedValue) {
      updateOptionName(blockId, optionId, updatedValue) // Update with new value
    } else {
      console.error('Input value is EMPTY!')
    }
  }

  const handleupdateOptionUrlSubmit = (e, blockId, optionId) => {
    e.preventDefault() // Prevent page reload
    // console.log(e.target)
    const formData = new FormData(e.target) // Get form values
    console.log('FormData Entries:', [...formData.entries()]) // Debug: Log all form values

    const updatedValue = formData.get('updatedOptionUrl') // Get input value
    console.log('Updated Value:', updatedValue) // Debugging

    if (updatedValue) {
      updateOptionUrl(blockId, optionId, updatedValue) // Update with new value
    } else {
      console.error('Input value is EMPTY!')
    }
  }

  const handleupdateItemTextSubmit = (e, blockId) => {
    e.preventDefault() // Prevent page reload
    // console.log(e.target)
    const formData = new FormData(e.target) // Get form values
    console.log('FormData Entries:', [...formData.entries()]) // Debug: Log all form values

    const updatedValue = formData.get('textbox') // Get input value
    console.log('Updated Value:', updatedValue) // Debugging

    if (updatedValue) {
      updateItemText(blockId, updatedValue) // Update with new value
    } else {
      console.error('Input value is EMPTY!')
    }
  }

  const handleInputChange = (block_id, field, newValue) => {
    setInputValues(prev => ({
      ...prev,
      [block_id]: {
        ...prev[block_id], // Keep existing data for the block
        [field]: newValue // Update specific field (text, option, etc.)
      }
    }))
  }

  useEffect(() => {
    const handleFocusChange = () => {
      setActiveInputId(document.activeElement?.dataset.id || null)
      console.log('active input:', document.activeElement?.dataset.id)
    }

    document.addEventListener('focusin', handleFocusChange)
    return () => document.removeEventListener('focusin', handleFocusChange)
  })

  useEffect(() => {
    if (activeInputId && inputRefs.current[activeInputId]) {
      inputRefs.current[activeInputId].focus()
    }
  })

  const handlegetexistconvo = async id => {
    if (sessionStorage.getItem('store_id')) {
      const res = getconvolist.find(n => n.id === id)

      if (!res) {
        console.error(`No conversation found with id: ${id}`)
        return // Exit the function to prevent further errors
      }

      console.log('config', res.config)

      try {
        const config = typeof res.config == "object" ? res.config : JSON.parse(res.config)

        // Update state values with the parsed config
        setstoreconfigid(config.convo_id)
        SetstoreblockObject(config.blocks || []) // Ensure it's an array
        SetConnectionArray(
          Object.fromEntries(
            Object.entries(config.connection || {}).map(([key, value]) => [
              key,
              typeof value === 'string' ? [value] : value
            ])
          )
        )
        SetstoreLabel(config.label || '') // Store the label

        console.log('get convo id', res.id)
        setstoreconvoid(res.id)
        console.log('Position:', res.position)
        setstoreconvoposition(res.position)
        console.log('Parsed Config:', config)

        setshowupdateconvomodule(true)
        SetopenConvoModule(true)
      } catch (error) {
        console.error('Error parsing config JSON:', error)
      }
    }
  }

  const Connectionmenuitems = ({ specificKey }) => {
    // Get the connections for the specificKey
    const connections = connectionArray[specificKey]

    // Return early if no connections exist
    if (!connections) {
      return null
    }
    // console.log('extracted connections', connections)
    return (
      <div className='ml-6 mt-4 border-[1px] border-black border-opacity-30 rounded-lg p-3 connection-tree-outline'>
        <div className='pl-4'>
          {Array.isArray(connections) ? (
            connections.map(connection => {
              return storeblockObject.map((item, index) => {
                var block_id = item.id

                if (item.id === connection) {
                  return (
                    <div key={item.id} className='relative group w-full mt-1'>
                      {/* Check if the type is TEXT_OPTIONS and if there are options */}
                      {item.type === 'TEXT_OPTIONS' && item.data.options ? (
                        <div key={item.id}>
                          {/* Render the text input for TEXT_OPTIONS */}
                          <p class='text-sm text-[#999] mb-1 font-semibold'>
                            Text Message
                          </p>
                          <form
                            onSubmit={e =>
                              handleupdateItemTextSubmit(e, block_id)
                            }
                            className='w-full flex items-center'
                          >
                            <input
                              ref={el => {
                                if (el) inputRefs.current[item.id] = el
                              }}
                              type='text'
                              name='textbox'
                              placeholder='Enter Text'
                              // value={inputValues[block_id]?.text ?? item.data.text}
                              value={item.data.text}
                              // defaultValue={item.data.text}
                              // onChange={e => handleInputChange(block_id, 'text', e.target.value)}
                              className='w-[250px] border-[1px] border-black border-opacity-30 py-2 px-3 rounded-lg text-sm focus:outline-none'
                              onChange={e => {
                                const updatedText = e.target.value
                                // Update the item text in the storeblockObject array
                                updateItemText(block_id, updatedText)
                              }}
                              data-id={item.id} // Unique identifier
                              // onBlur={(e)=>{
                              //   const updatedText = e.target.value;
                              //   updateItemText(block_id, updatedText)
                              // }}
                            />
                            {/* <button type='submit' className='flex items-center'>
                            <IonIcon
                              name='checkmark-circle-outline'
                              className='text-green-500 cursor-pointer ml-2'
                            />
                          </button> */}
                          </form>
                          {/* render the options */}
                          <div className='mt-2 ml-9 flex flex-col gap-2'>
                            {item.data.options.map((option, optionindex) => {
                              option_id = option.id
                              // console.log(optionindex)
                              return (
                                <div key={option.id}>
                                  <p className='text-sm text-[#999] mb-1 font-semibold'>
                                    Option: {optionindex + 1}
                                  </p>
                                  <div className='relative flex gap-2 items-center'>
                                    {/* Render BUTTON type input */}
                                    {option.type === 'BUTTON' ? (
                                      <>
                                        <form
                                          onSubmit={e =>
                                            handleupdateOptionTextSubmit(
                                              e,
                                              block_id,
                                              option.id
                                            )
                                          }
                                          className='w-[250px] flex items-center'
                                        >
                                          <input
                                            ref={el => {
                                              if (el)
                                                inputRefs.current[option.id] =
                                                  el
                                            }}
                                            type='text'
                                            // defaultValue={option.text}
                                            value={option.text}
                                            name='optionText'
                                            required
                                            placeholder='Enter Text'
                                            className='border-[1px] border-black border-opacity-30 py-2 px-3 rounded-lg w-full text-sm focus:outline-none'
                                            onChange={e => {
                                              const updatedOptionText =
                                                e.target.value
                                              updateOptionText(
                                                block_id,
                                                option.id,
                                                updatedOptionText
                                              )
                                            }}
                                            // onBlur={(e)=>{
                                            //   const updatedOptionText = e.target.value;
                                            //   updateOptionText(block_id, option.id, updatedOptionText)
                                            // }}
                                            data-id={option.id} // Unique identifier
                                          />
                                          {/* <button
                                          type='submit'
                                          className='flex items-center'
                                        >
                                          <IonIcon
                                            name='checkmark-circle-outline'
                                            className='text-green-500 cursor-pointer ml-2'
                                          />
                                        </button> */}
                                        </form>
                                      </>
                                    ) : (
                                      // Render LINK type input
                                      <div className='flex flex-col items-center gap-2 w-[250px]'>
                                        <form
                                          onSubmit={e =>
                                            handleupdatedOptionNameTextSubmit(
                                              e,
                                              block_id,
                                              option.id
                                            )
                                          }
                                          className='w-full flex items-center'
                                        >
                                          <input
                                            ref={el => {
                                              if (el)
                                                inputRefs.current[
                                                  `${block_id}-input1`
                                                ] = el
                                            }}
                                            type='text'
                                            name='updatedOptionName'
                                            placeholder='e.g. About us'
                                            value={option.text || ''} // Assuming 'name' for link label
                                            // defaultValue={option.name || ''} // Assuming 'name' for link label
                                            className='border-[1px] border-black border-opacity-30 py-2 px-3 rounded-lg w-full text-sm focus:outline-none'
                                            onChange={e => {
                                              const updatedOptionName =
                                                e.target.value
                                              updateOptionName(
                                                block_id,
                                                option.id,
                                                updatedOptionName
                                              )
                                            }}
                                            data-id={`${block_id}-input1`}
                                          />
                                          {/* <button
                                          type='submit'
                                          className='flex items-center'
                                        >
                                          <IonIcon
                                            name='checkmark-circle-outline'
                                            className='text-green-500 cursor-pointer ml-2'
                                          />
                                        </button> */}
                                        </form>
                                        <form
                                          onSubmit={e =>
                                            handleupdateOptionUrlSubmit(
                                              e,
                                              block_id,
                                              option.id
                                            )
                                          }
                                          className='w-full flex items-center'
                                        >
                                          <input
                                            ref={el => {
                                              if (el)
                                                inputRefs.current[
                                                  `${block_id}-input2`
                                                ] = el
                                            }}
                                            type='text'
                                            placeholder='Search or paste link'
                                            name='updatedOptionUrl'
                                            value={option.url || ''} // Assuming 'url' for link
                                            // defaultValue={option.url || ''} // Assuming 'url' for link
                                            className='border-[1px] border-black border-opacity-30 py-2 px-3 rounded-lg w-full text-sm focus:outline-none'
                                            onChange={e => {
                                              const updatedOptionUrl =
                                                e.target.value
                                              updateOptionUrl(
                                                block_id,
                                                option.id,
                                                updatedOptionUrl
                                              )
                                            }}
                                            data-id={`${block_id}-input2`}
                                          />
                                          {/* <button
                                          type='submit'
                                          className='flex items-center'
                                        >
                                          <IonIcon
                                            name='checkmark-circle-outline'
                                            className='text-green-500 cursor-pointer ml-2'
                                          />
                                        </button> */}
                                        </form>
                                      </div>
                                    )}

                                    <div className='flex gap-2 justify-center h-full mt-1'>
                                      {option.type !== 'LINK' &&
                                        (() => {
                                          const idArray =
                                            connectionArray[option.id] || [] // Ensure it's an array
                                          const exists = idArray.some(
                                            id =>
                                              storeblockObject?.some(
                                                item => item.id === id
                                              ) // Check against item.id, not options
                                          )

                                          return exists ? (
                                            !connectionArray[option.id] && (
                                              <IonIcon
                                                onClick={() => {
                                                  SetcurrentblockId(block_id)
                                                  SetcurrentoptionId(option.id)
                                                  Setshowconnectionelement(true)
                                                  handleconnection(option.id)
                                                  setcallconnection(true)
                                                }}
                                                name='git-branch-outline' // Show original icon when no connection exists
                                                className='text-gray-500 cursor-pointer'
                                                style={{
                                                  transform:
                                                    'scaleX(-1) scaleY(-1)'
                                                }}
                                              />
                                            )
                                          ) : (
                                            <IonIcon
                                              onClick={() => {
                                                SetcurrentblockId(block_id)
                                                SetcurrentoptionId(option.id)
                                                Setshowconnectionelement(true)
                                                handleconnection(option.id)
                                                setcallconnection(true)
                                              }}
                                              name='git-branch-outline' // Show a different icon if connection exists
                                              className='cursor-pointer'
                                              style={{
                                                transform:
                                                  'scaleX(-1) scaleY(-1)'
                                              }}
                                            />
                                          )
                                        })()}

                                      <IonIcon
                                        onClick={() =>
                                          handledeleteoption(
                                            block_id,
                                            option.id
                                          )
                                        }
                                        name='trash-outline'
                                        className=' text-gray-500 cursor-pointer'
                                      />
                                    </div>
                                  </div>
                                  {/* connection array */}
                                  {showconnectionelement &&
                                    currentblockId === item.id &&
                                    currentoptionId === option.id && (
                                      <div className='mt-1'>
                                        <p className='text-sm text-[#999] mb-1 font-semibold'>
                                          Select Elements
                                        </p>
                                        {/* 
                                    options
                                          ? [
                                              {
                                                icon: 'radio-button-on-outline',
                                                label: 'Button',
                                                type: 'BUTTON'
                                              },
                                              {
                                                icon: 'link-outline',
                                                label: 'Link',
                                                type: 'LINK'
                                              }
                                            ]
                                          : [
                                              {
                                                icon: 'document-text-outline',
                                                label: 'TEXT_OPTIONS',
                                                type: 'text_options'
                                              },
                                              {
                                                icon: 'text-outline',
                                                label: 'TEXT',
                                                type: 'text'
                                              }
                                            ]
                                    */}
                                        <div className='flex gap-2'>
                                          {[
                                            {
                                              icon: 'radio-button-on-outline',
                                              label: 'Button',
                                              type: 'BUTTON'
                                            },
                                            {
                                              icon: 'link-outline',
                                              label: 'Link',
                                              type: 'LINK'
                                            },
                                            {
                                              icon: 'text-outline',
                                              label: 'TEXT',
                                              type: 'text'
                                            }
                                          ].map((item, index) => (
                                            <div
                                              className='flex flex-col items-center justify-center mb-1 mt-1'
                                              key={index}
                                            >
                                              <div
                                                className=' flex flex-col items-center justify-center w-[40px] h-[40px] p-6 rounded-[12px] hover:bg-[#7c50dd] hover:text-white cursor-pointer border-[1px]'
                                                onClick={() => {
                                                  handlecreateblock(
                                                    item.label,
                                                    item.type,
                                                    option.id
                                                  )
                                                  setcallconnection(true)
                                                }}
                                              >
                                                <IonIcon
                                                  name={item.icon}
                                                  className=''
                                                />
                                              </div>
                                              <p className='text-[9px] text-[#030303] font-semibold mt-1'>
                                                {item.label}
                                              </p>
                                            </div>
                                          ))}

                                          <IonIcon
                                            name='close-circle-outline'
                                            className=' cursor-pointer'
                                            onClick={() => {
                                              Setshowconnectionelement(false)
                                              setoptions(false)
                                            }}
                                          />
                                        </div>
                                      </div>
                                    )}

                                  {connectionArray[option.id] && (
                                    <div className='ml-5 mt-2'>
                                      {(() => {
                                        console.log(connectionArray[option.id])

                                        const idArray =
                                          connectionArray[option.id] || [] // Ensure it's an array
                                        const exists = idArray.some(
                                          id =>
                                            storeblockObject?.some(
                                              item => item.id === id
                                            ) // Check against item.id, not options
                                        )
                                        console.log(exists)
                                        // setshowbranch(exists)
                                        return exists ? (
                                          <Connectionmenuitems
                                            specificKey={option.id}
                                          />
                                        ) : null
                                      })()}
                                    </div>
                                  )}
                                </div>
                              )
                            })}

                            {/* Show option elements when needed */}
                            {showoptionelement && currentblockId === item.id && (
                              <div className='mt-1'>
                                <p className='text-sm text-[#999] mb-1 font-semibold'>
                                  Select option elements
                                </p>

                                <div className='flex gap-2'>
                                  {[
                                    {
                                      icon: 'radio-button-on-outline',
                                      label: 'Button',
                                      type: 'BUTTON'
                                    },
                                    {
                                      icon: 'link-outline',
                                      label: 'Link',
                                      type: 'LINK'
                                    }
                                  ].map((element, index) => (
                                    <div
                                      className='flex flex-col items-center justify-center mb-1 mt-1'
                                      key={index}
                                    >
                                      <div
                                        className='flex flex-col items-center justify-center w-[40px] h-[40px] p-6 rounded-[12px] hover:bg-[#7c50dd] hover:text-white cursor-pointer border-[1px]'
                                        onClick={() =>
                                          handleinsertoption(
                                            block_id,
                                            option_id,
                                            element.type
                                          )
                                        }
                                      >
                                        <IonIcon name={element.icon} />
                                      </div>
                                      <p className='text-[9px] text-[#030303] font-semibold mt-1'>
                                        {element.label}
                                      </p>
                                    </div>
                                  ))}

                                  <IonIcon
                                    name='close-circle-outline'
                                    className='cursor-pointer'
                                    onClick={() => {
                                      Setshowoptionelement(false)
                                      setoptions(false)
                                    }}
                                  />
                                </div>
                              </div>
                            )}

                            {/* Show the option button */}
                            <div
                              className='flex items-center justify-start cursor-pointer border-[1px] border-opacity-30 border-black px-4 py-3 rounded-lg'
                              onClick={() => {
                                Setshowoptionelement(true)
                                SetcurrentblockId(block_id)
                              }}
                            >
                              <IonIcon
                                name='add-circle-outline'
                                className='text-[#7C50DD] text-[18px] cursor-pointer'
                              />
                              <p className='text-[14px] text-[#7C50DD] font-semibold  ml-1'>
                                Add Block Item Options
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div>
                          {/* Render a different UI for blocks without options */}
                          <form
                            onSubmit={e =>
                              handleupdateItemTextSubmit(e, block_id)
                            }
                            className='w-[250px] flex gap-2 items-center'
                          >
                            <input
                              ref={el => {
                                if (el) inputRefs.current[item.id] = el
                              }}
                              type='text'
                              name='textbox'
                              placeholder='Enter Text'
                              value={item.data.text}
                              className='border-[1px] border-black border-opacity-30 py-2 px-3 rounded-lg w-[250px] text-sm focus:outline-none'
                              onChange={e => {
                                const updatedText = e.target.value
                                updateItemText(block_id, updatedText)
                              }}
                              data-id={item.id}
                            />

                            <IonIcon
                              onClick={() => handledeleteblock(block_id)}
                              name='trash-outline'
                              className=' text-gray-500 cursor-pointer'
                            />
                            {/* <button type='submit' className='flex items-center'>
                            <IonIcon
                              name='checkmark-circle-outline'
                              className='text-green-500 cursor-pointer ml-2'
                            />
                          </button> */}
                          </form>
                        </div>
                      )}
                    </div>
                  )
                }
                return null
              })
            })
          ) : (
            <p className='text-sm'>No connections found.</p>
          )}
        </div>
      </div>
    )
  }

  const renderMenuitems = () => {
    return (
      <div className='convoflow-horizontal-screen-extent flex flex-col gap-2 mt-2'>
        {storeblockObject.map((item, index) => {
          // console.log('block_id:', item.id)
          var block_id = item.id
          // Check if the block_id exists in the connectionArray
          const idExists = Object.values(connectionArray).some(valueArray =>
            valueArray.includes(block_id)
          )

          // console.log(idExists)

          if (idExists) {
            return null
          }
          return (
            <div key={item.id} className='relative group w-full'>
              {/* Check if the type is TEXT_OPTIONS and if there are options */}
              {item.type === 'TEXT_OPTIONS' && item.data.options ? (
                <div key={item.id}>
                  {/* Render the text input for TEXT_OPTIONS */}
                  <p class='text-sm text-[#999] mb-1 font-semibold'>
                    Text Message
                  </p>

                  <form
                    onSubmit={e => handleupdateItemTextSubmit(e, block_id)}
                    className='w-full flex items-center'
                  >
                    <input
                      type='text'
                      name='textbox'
                      placeholder='Enter Text'
                      defaultValue={item.data.text}
                      className='w-[250px] border-[1px] border-black border-opacity-30 py-2 px-3 rounded-lg text-sm focus:outline-none'
                      onChange={e => {
                        const updatedText = e.target.value
                        // Update the item text in the storeblockObject array
                        updateItemText(block_id, updatedText)
                      }}
                    />
                    {/* <button type='submit' className='flex items-center'>
                      <IonIcon
                        name='checkmark-circle-outline'
                        className='text-green-500 cursor-pointer ml-2'
                      />
                    </button> */}
                  </form>

                  {/* render the options */}
                  <div className='mt-2 ml-9 flex flex-col gap-2'>
                    {item.data.options.map((option, index) => {
                      option_id = option.id
                      // console.log(option)
                      return (
                        <div key={index}>
                          <p className='text-sm text-[#999] mb-1 font-semibold'>
                            Option: {index + 1}
                          </p>
                          <div className='relative flex gap-2 items-center'>
                            {/* Render BUTTON type input */}
                            {option.type === 'BUTTON' ? (
                              <>
                                <form
                                  onSubmit={e =>
                                    handleupdateOptionTextSubmit(
                                      e,
                                      block_id,
                                      option.id
                                    )
                                  }
                                  className='w-[250px] flex items-center'
                                >
                                  <input
                                    type='text'
                                    value={option.text}
                                    name='optionText'
                                    required
                                    placeholder='Enter Text'
                                    className='border-[1px] border-black border-opacity-30 py-2 px-3 rounded-lg w-full text-sm focus:outline-none'
                                    onChange={e => {
                                      const updatedOptionText = e.target.value
                                      updateOptionText(
                                        block_id,
                                        option.id,
                                        updatedOptionText
                                      )
                                    }}
                                  />
                                  {/* <button
                                    type='submit'
                                    className='flex items-center'
                                  >
                                    <IonIcon
                                      name='checkmark-circle-outline'
                                      className='text-green-500 cursor-pointer ml-2'
                                    />
                                  </button> */}
                                </form>
                              </>
                            ) : (
                              // Render LINK type input
                              <div className='flex flex-col items-center gap-2 w-[250px]'>
                                <form
                                  onSubmit={e =>
                                    handleupdatedOptionNameTextSubmit(
                                      e,
                                      block_id,
                                      option.id
                                    )
                                  }
                                  className='w-full flex items-center'
                                >
                                  <input
                                    type='text'
                                    name='updatedOptionName'
                                    placeholder='e.g. About us'
                                    value={option.text || ''} // Assuming 'name' for link label
                                    className='border-[1px] border-black border-opacity-30 py-2 px-3 rounded-lg w-full text-sm focus:outline-none'
                                    onChange={e => {
                                      const updatedOptionName = e.target.value
                                      updateOptionName(
                                        block_id,
                                        option.id,
                                        updatedOptionName
                                      )
                                    }}
                                  />
                                  {/* <button
                                    type='submit'
                                    className='flex items-center'
                                  >
                                    <IonIcon
                                      name='checkmark-circle-outline'
                                      className='text-green-500 cursor-pointer ml-2'
                                    />
                                  </button> */}
                                </form>
                                <form
                                  onSubmit={e =>
                                    handleupdateOptionUrlSubmit(
                                      e,
                                      block_id,
                                      option.id
                                    )
                                  }
                                  className='w-full flex items-center'
                                >
                                  <input
                                    type='text'
                                    placeholder='Search or paste link'
                                    name='updatedOptionUrl'
                                    value={option.url || ''} // Assuming 'url' for link
                                    className='border-[1px] border-black border-opacity-30 py-2 px-3 rounded-lg w-full text-sm focus:outline-none'
                                    onChange={e => {
                                      const updatedOptionUrl = e.target.value
                                      updateOptionUrl(
                                        block_id,
                                        option.id,
                                        updatedOptionUrl
                                      )
                                    }}
                                  />
                                  {/* <button
                                    type='submit'
                                    className='flex items-center'
                                  >
                                    <IonIcon
                                      name='checkmark-circle-outline'
                                      className='text-green-500 cursor-pointer ml-2'
                                    />
                                  </button> */}
                                </form>
                              </div>
                            )}

                            <div className='flex gap-2 justify-center h-full mt-1'>
                              {option.type !== 'LINK' &&
                                (() => {
                                  const idArray =
                                    connectionArray[option.id] || [] // Ensure it's an array
                                  const exists = idArray.some(
                                    id =>
                                      storeblockObject?.some(
                                        item => item.id === id
                                      ) // Check against item.id, not options
                                  )

                                  return exists ? (
                                    !connectionArray[option.id] && (
                                      <IonIcon
                                        onClick={() => {
                                          SetcurrentblockId(block_id)
                                          SetcurrentoptionId(option.id)
                                          Setshowconnectionelement(true)
                                          handleconnection(option.id)
                                          setcallconnection(true)
                                        }}
                                        name='git-branch-outline' // Show original icon when no connection exists
                                        className='text-gray-500 cursor-pointer'
                                        style={{
                                          transform: 'scaleX(-1) scaleY(-1)'
                                        }}
                                      />
                                    )
                                  ) : (
                                    <IonIcon
                                      onClick={() => {
                                        SetcurrentblockId(block_id)
                                        SetcurrentoptionId(option.id)
                                        Setshowconnectionelement(true)
                                        handleconnection(option.id)
                                        setcallconnection(true)
                                      }}
                                      name='git-branch-outline' // Show a different icon if connection exists
                                      className='cursor-pointer'
                                      style={{
                                        transform: 'scaleX(-1) scaleY(-1)'
                                      }}
                                    />
                                  )
                                })()}

                              <IonIcon
                                onClick={() =>
                                  handledeleteoption(block_id, option.id)
                                }
                                name='trash-outline'
                                className='text-gray-500 cursor-pointer'
                              />
                            </div>
                          </div>
                          {/* connection array */}
                          {showconnectionelement &&
                            currentblockId === item.id &&
                            currentoptionId === option.id && (
                              <div className='border-[1px] border-opacity-30 border-black px-4 py-3 rounded-lg mt-3'>
                                <p className='text-sm text-[#999] mb-1 font-semibold'>
                                  Select Elements
                                </p>
                                {/* 
                              
                              options
                                    ? [
                                        {
                                          icon: 'radio-button-on-outline',
                                          label: 'Button',
                                          type: 'BUTTON'
                                        },
                                        {
                                          icon: 'link-outline',
                                          label: 'Link',
                                          type: 'LINK'
                                        }
                                      ]
                                    : [
                                        {
                                          icon: 'document-text-outline',
                                          label: 'TEXT_OPTIONS',
                                          type: 'text_options'
                                        },
                                        {
                                          icon: 'text-outline',
                                          label: 'TEXT',
                                          type: 'text'
                                        }
                                      ]
                              */}
                                <div className='flex gap-2'>
                                  {[
                                    {
                                      icon: 'radio-button-on-outline',
                                      label: 'Button',
                                      type: 'BUTTON'
                                    },
                                    {
                                      icon: 'link-outline',
                                      label: 'Link',
                                      type: 'LINK'
                                    },
                                    {
                                      icon: 'text-outline',
                                      label: 'TEXT',
                                      type: 'text'
                                    }
                                  ].map((item, index) => (
                                    <div
                                      className='flex flex-col items-center justify-center mb-1 mt-1'
                                      key={index}
                                    >
                                      <div
                                        className=' flex flex-col items-center justify-center w-[40px] h-[40px] p-6 rounded-[12px] hover:bg-[#7c50dd] hover:text-white cursor-pointer border-[1px]'
                                        onClick={() => {
                                          handlecreateblock(
                                            item.label,
                                            item.type,
                                            option.id
                                          )
                                          setcallconnection(true)
                                        }}
                                      >
                                        <IonIcon
                                          name={item.icon}
                                          className=''
                                        />
                                      </div>
                                      <p className='text-[9px] text-[#030303] font-semibold mt-1'>
                                        {item.label}
                                      </p>
                                    </div>
                                  ))}

                                  <IonIcon
                                    name='close-circle-outline'
                                    className=' cursor-pointer'
                                    onClick={() => {
                                      Setshowconnectionelement(false)
                                      setoptions(false)
                                    }}
                                  />
                                </div>
                              </div>
                            )}

                          <div className='ml-5 mt-2'>
                            {(() => {
                              console.log(connectionArray[option.id])

                              const idArray = connectionArray[option.id] || [] // Ensure it's an array
                              const exists = idArray.some(
                                id =>
                                  storeblockObject?.some(item => item.id === id) // Check against item.id, not options
                              )
                              console.log(exists)
                              // setshowbranch(exists)
                              return exists ? (
                                <Connectionmenuitems specificKey={option.id} />
                              ) : null
                            })()}
                          </div>
                        </div>
                      )
                    })}

                    {/* Show option elements when needed */}
                    {showoptionelement && currentblockId === item.id && (
                      <div className='mt-1'>
                        <p className='text-sm text-[#999] mb-1 font-semibold'>
                          Select option elements
                        </p>

                        <div className='flex gap-2'>
                          {[
                            {
                              icon: 'radio-button-on-outline',
                              label: 'Button',
                              type: 'BUTTON'
                            },
                            {
                              icon: 'link-outline',
                              label: 'Link',
                              type: 'LINK'
                            }
                          ].map((element, index) => (
                            <div
                              className='flex flex-col items-center justify-center mb-1 mt-1'
                              key={index}
                            >
                              <div
                                className='flex flex-col items-center justify-center w-[40px] h-[40px] p-6 rounded-[12px] hover:bg-[#7c50dd] hover:text-white cursor-pointer border-[1px]'
                                onClick={() =>
                                  handleinsertoption(
                                    block_id,
                                    option_id,
                                    element.type
                                  )
                                }
                              >
                                <IonIcon name={element.icon} />
                              </div>
                              <p className='text-[9px] text-[#030303] font-semibold mt-1'>
                                {element.label}
                              </p>
                            </div>
                          ))}

                          <IonIcon
                            name='close-circle-outline'
                            className='cursor-pointer'
                            onClick={() => {
                              Setshowoptionelement(false)
                              setoptions(false)
                            }}
                          />
                        </div>
                      </div>
                    )}

                    {/* Show the option button */}
                    <div
                      className='flex items-center justify-start cursor-pointer border-[1px] border-opacity-30 border-black px-4 py-3 rounded-lg'
                      onClick={() => {
                        Setshowoptionelement(true)
                        SetcurrentblockId(block_id)
                      }}
                    >
                      <IonIcon
                        name='add-circle-outline'
                        className='text-[#7C50DD] text-[18px] cursor-pointer'
                      />
                      <p className='text-[14px] text-[#7C50DD] font-semibold  ml-1'>
                        Add Block Item Options
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  {/* Render a different UI for blocks without options */}
                  <form
                    onSubmit={e => handleupdateItemTextSubmit(e, block_id)}
                    className='w-full flex gap-2 items-center'
                  >
                    <input
                      type='text'
                      name='textbox'
                      placeholder='Enter Text'
                      value={item.data.text}
                      className='w-[250px] border-[1px] border-black border-opacity-30 py-2 px-3 rounded-lg text-sm focus:outline-none'
                      onChange={e => {
                        const updatedText = e.target.value
                        updateItemText(block_id, updatedText)
                      }}
                    />
                    <IonIcon
                      onClick={() => handledeleteblock(block_id)}
                      name='trash-outline'
                      className=' text-gray-500 cursor-pointer'
                    />
                    {/* <button type='submit' className='flex items-center'>
                      <IonIcon
                        name='checkmark-circle-outline'
                        className='text-green-500 cursor-pointer ml-2'
                      />
                    </button> */}
                  </form>
                </div>
              )}
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <>
      {showModal && (
        <div className='fixed inset-0 bg-black bg-opacity-25 flex justify-center items-center z-[100]'>
          <div className='bg-[#F4F4F4] rounded-[24px] w-[456px] h-[240px] mx-5 p-6 border-[1.6px] border-[#FFFFFF]  shadow[-1px_0px_0px_0px_inset]'>
            <div className='block'>
              <div className='w-full flex justify-end items-start '>
                <svg
                  onClick={() => {
                    setShowModal(false)
                  }}
                  width='26'
                  height='26'
                  className='cursor-pointer'
                  viewBox='0 0 26 26'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M7.88702 6.73798C7.56972 6.42067 7.05528 6.42067 6.73798 6.73798C6.42067 7.05528 6.42067 7.56972 6.73798 7.88702L11.851 13L6.73798 18.113C6.42067 18.4303 6.42067 18.9447 6.73798 19.262C7.05528 19.5793 7.56972 19.5793 7.88702 19.262L13 14.149L18.113 19.262C18.4303 19.5793 18.9447 19.5793 19.262 19.262C19.5793 18.9447 19.5793 18.4303 19.262 18.113L14.149 13L19.262 7.88702C19.5793 7.56972 19.5793 7.05528 19.262 6.73798C18.9447 6.42067 18.4303 6.42067 18.113 6.73798L13 11.851L7.88702 6.73798Z'
                    fill='#373737'
                  />
                </svg>
              </div>
              <div className=' mt-2 flex flex-col items-center justify-center gap-3'>
                <p className='font-[800] text-[20px] leading-[38.25px] text-[#252530]'>
                  Delete
                </p>
                <p className='font-[400] text-[16px] leading-[21px]'>
                  Are you sure want to delete
                </p>
                <div className='flex mt-4 gap-4'>
                  <button
                    // onClick={e => handleCancelDelete(e)}
                    onClick={() => {
                      setShowModal(false)
                    }}
                    className='bg-[#232328] w-[136px] h-[39px] p-[10px] font-[600] text-[14px] leading-[19.12px] rounded-[39px] text-[#FFFFFF]'
                  >
                    No
                  </button>
                  <button
                    // onClick={e => handleConfirmDelete(deletingStore, e)}
                    onClick={() => {
                      deleteconvoflow()
                      setShowModal(false)
                      setshowupdateconvomodule(false)
                    }}
                    className='bg-[#E22400] w-[136px] h-[39px] p-[10px] font-[600] text-[14px] leading-[19.12px] rounded-[39px] text-[#FFFFFF]'
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {openConvoModule && (
        <div className='fixed inset-0 flex items-center p-5 w-full justify-center z-[90] bg-black bg-opacity-25'>
          <div className='bg-[#FFFFFF] p-5 sm:p-7 shadow-lg convoflow-popup-window mx-auto my-[30px] rounded-[24px] border-[1.6px] border-white overflow-y-auto min-h-[40%] max-h-[90%] scroll-m-16 scrollbar-hide'>
            <div className='flex justify-between'>
              {showupdateconvomodule === true ? (
                <h2 className='font-extrabold text-[21px] mb-9'>
                  Update Conversation Flow
                </h2>
              ) : (
                <h2 className='font-extrabold text-[21px] mb-9'>
                  Create Conversation Flow
                </h2>
              )}

              <button
                className='w-[30px] sm:w-[40px] h-[30px] sm:h-[40px] text-[14px] sm:text-[18px] cursor-pointer'
                onClick={() => {
                  SetopenConvoModule(false)
                  setshowupdateconvomodule(false)
                  SetstoreblockObject([])
                  SetConnectionArray({})
                  SetstoreLabel('')
                }}
              >
                <svg
                  width='30'
                  height='30'
                  viewBox='0 0 30 30'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M9.10041 7.77459C8.7343 7.40847 8.1407 7.40847 7.77459 7.77459C7.40847 8.1407 7.40847 8.7343 7.77459 9.10041L13.6742 15L7.77459 20.8996C7.40847 21.2657 7.40847 21.8593 7.77459 22.2254C8.1407 22.5915 8.7343 22.5915 9.10041 22.2254L15 16.3258L20.8996 22.2254C21.2657 22.5915 21.8593 22.5915 22.2254 22.2254C22.5915 21.8593 22.5915 21.2657 22.2254 20.8996L16.3258 15L22.2254 9.10041C22.5915 8.7343 22.5915 8.1407 22.2254 7.77459C21.8593 7.40847 21.2657 7.40847 20.8996 7.77459L15 13.6742L9.10041 7.77459Z'
                    fill='#373737'
                  ></path>
                </svg>
              </button>
            </div>
            <div className='w-full px-5 py-2 bg-white border-[1px] border-black border-opacity-30 rounded-[12px] flex flex-col'>
              <div className='flex flex-col'>
                <label className='cursor-default text-[14px] text-[#646464] font-semibold mb-1'>
                  Name
                </label>
                <input
                  onChange={e => SetstoreLabel(e.target.value)}
                  value={storeLabel}
                  type='text'
                  className='border-[1px] border-opacity-30 border-black py-2 px-2 rounded-lg focus:outline-none text-[14px] text-[#383838] focus:bg-[#f7f7f7] hover:bg-[#f7f7f7] placeholder:text-[#383838]'
                  placeholder='example: How can I help you today?✌️'
                />
              </div>
              <div className='flex mt-3'>
                <p className='text-[14px] text-[#646464] font-semibold mb-1'>
                  Preview Label:
                </p>
                <p className='cursor-default text-[14px] text-[#646464] font-semibold mb-1 ml-1'>
                  {storeLabel}
                </p>
              </div>
            </div>
            <div className='w-full overflow-x-auto mt-3 bg-white rounded-[12px] flex flex-col border-[1px] border-black border-opacity-30 rounded-[12px] px-4 py-3'>
              <h3 className='cursor-default text-[14px] text-[#646464] font-semibold'>
                Block Items
              </h3>
              {storeblockObject.length !== 0 ? renderMenuitems() : <></>}

              {showconvoelement && (
                <div className='border-[1px] border-opacity-30 border-black px-4 py-3 rounded-lg mt-2'>
                  <p className='text-sm text-[#999] mb-1 font-semibold'>
                    Select Elements
                  </p>
                  {/* 
                  options
                      ? [
                          {
                            icon: 'radio-button-on-outline',
                            label: 'Button',
                            type: 'BUTTON'
                          },
                          { icon: 'link-outline', label: 'Link', type: 'LINK' }
                        ]
                      : [
                          {
                            icon: 'document-text-outline',
                            label: 'TEXT_OPTIONS',
                            type: 'text_options'
                          },
                          { icon: 'text-outline', label: 'TEXT', type: 'text' }
                        ]
                  
                  */}
                  <div className='flex gap-2'>
                    {[
                      {
                        icon: 'radio-button-on-outline',
                        label: 'Button',
                        type: 'BUTTON'
                      },
                      { icon: 'link-outline', label: 'Link', type: 'LINK' },
                      { icon: 'text-outline', label: 'TEXT', type: 'TEXT' }
                    ].map((item, index) => (
                      <div
                        className='flex flex-col items-center justify-center mb-1 mt-1'
                        key={index}
                      >
                        <div
                          className='group flex flex-col items-center justify-center w-[40px] h-[40px] p-6 rounded-[12px] hover:bg-[#7c50dd] cursor-pointer border-[1px]'
                          onClick={() =>
                            handlecreateblock(item.label, item.type)
                          }
                        >
                          <IonIcon
                            name={item.icon}
                            className='group-hover:text-white'
                          />
                        </div>
                        <p className='text-[9px] text-[#030303] font-semibold mt-1'>
                          {item.label}
                        </p>
                      </div>
                    ))}

                    <IonIcon
                      name='close-circle-outline'
                      className='group-hover:text-white cursor-pointer'
                      onClick={() => {
                        Setconvoelement(false)
                        setoptions(false)
                        setcallconnection(false)
                      }}
                    />
                  </div>
                </div>
              )}
              {(storeblockObject.length == 0) ? (
              <div
                className='mt-2 flex items-center justify-start cursor-pointer border-[1px] border-opacity-30 border-black px-4 py-3 rounded-lg'
                onClick={() => {
                  Setconvoelement(true)
                  setcallconnection(false)
                }}
              >
                <IonIcon
                  name='add-circle-outline'
                  className='text-[#7C50DD] text-[18px] cursor-pointer'
                />
                <p className='text-[14px] text-[#7C50DD] font-semibold  ml-1'>
                  Add Block Item
                </p>
              </div>
              ) : (<></>)}
            </div>
            {/* button actions */}
            <div className='flex justify-end mt-4 gap-2'>
              {/* <button type="submit" class="bg-[#dd5050]  text-white px-[30px] h-[38px] rounded-[55px] font-semibold text-[14px]">Cancel</button> */}
              {showupdateconvomodule === true ? (
                <div className='flex gap-2'>
                  <button
                    // onClick={deleteconvoflow}
                    onClick={() => {
                      setShowModal(true)
                    }}
                    type='submit'
                    class='bg-[#dd5050]  text-white px-[30px] h-[38px] rounded-[55px] font-semibold text-[14px]'
                  >
                    Delete
                  </button>
                  <button
                    onClick={updateconvoflowarray}
                    type='submit'
                    class='bg-[#7C50DD]  text-white px-[30px] h-[38px] rounded-[55px] font-semibold text-[14px]'
                  >
                    Update
                  </button>
                </div>
              ) : (
                <button
                  onClick={postconvoflowarray}
                  type='submit'
                  class='bg-[#7C50DD]  text-white px-[30px] h-[38px] rounded-[55px] font-semibold text-[14px]'
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      )}

      <div className='w-full'>
        <div className='w-full overflow-clip'>
          <p className='text-[#2D3748] text-[14px] break-words font-[400]'>
            Create New Conversation Flow
          </p>
        </div>

        <div className='flex justify-start mt-2'>
          <button
            className='px-10 py-2 bg-[#7C50DD] flex justify-center items-center text-white rounded-full text-[14px]'
            onClick={() => {
              SetopenConvoModule(true)
              SetstoreConvoId(uuidv4())
            }}
          >
            <span className='text-[15px] mr-2 font-[700]'>+</span>
            <p className='mr-2 text-[12px] font-[700] leading-5 '>Create</p>
          </button>
        </div>
        {/* list of craeted convo flow */}
        <div className='relative max-w-md h-[400px] mt-3'>
          {/* Top Shadow (Only visible if content overflows) */}
          {/* <div className='absolute top-0 left-0 w-full md:max-w-[350px] h-5 bg-gradient-to-b from-gray-300 to-transparent z-10 pointer-events-none'></div> */}

          {/* Scrollable Content */}
          <div
            className='py-2 scrollbar-hide overflow-y-auto h-full relative'
            onScroll={handleScroll}
          >
            {getconvolist?.map((item, index) => {
              let title = ''
              try {
                const config =
                  typeof item.config === 'object'
                    ? item.config
                    : JSON.parse(item.config)
                title = config.label || 'Untitled' // Default to 'Untitled' if label is missing
              } catch (error) {
                title = 'Untitled' // Handle invalid JSON
              }

              return (
                <button
                  key={index}
                  className='group w-full md:max-w-[350px] hover:shadow-md px-3 py-3 mt-2 flex justify-between items-center rounded-lg bg-white'
                  onClick={() => handlegetexistconvo(item.id)}
                >
                  <div className='flex flex-col items-start'>
                    <p className='mr-2 text-[12px] font-[800] leading-5'>
                      {title}
                    </p>
                  </div>
                  <IonIcon
                    name='create-outline'
                    className='text-[#a7a7a7] group-hover:text-[#000]'
                  />
                </button>
              )
            })}
          </div>

          {/* Bottom Shadow (Only visible if content overflows) */}
          {/* <div className='absolute bottom-0 left-0 w-full md:max-w-[350px] h-5 bg-gradient-to-t from-gray-300 to-transparent z-10 pointer-events-none'></div> */}
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default ConvoFlow
