import React, { useEffect, useState, useRef } from 'react'
import { Get_tickets } from '../../services/data'
import useDebounce from '../../hooks/useDebounce'
import { tr } from 'date-fns/locale'
import IonIcon from '@reacticons/ionicons'
import { update_tickets } from '../../services/data'
import { toast, ToastContainer } from 'react-toastify'
import { GetChatLog } from '../../services/data'
import Conversation from '../Chats/conversation'
import { delete_tickets } from '../../services/data'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { format } from 'date-fns'
import { create } from '@mui/material/styles/createTransitions'
import TrainFilterDropdownPopup from '../../components/trainfilterdropdownpopup'
import FilterDropDownHook from '../../hooks/filterdropdownhook'
import useRippleEffect from '../../hooks/tripleEffects'
import { TfiFilter } from 'react-icons/tfi'
import { Tooltip } from 'react-tooltip'


const TicketManagement = () => {
  const [tickets, setTickets] = useState([])
  const [search, setSearch] = useState('')
  const [statusfilter, setstatusfilter] = useState('')
  const [datefilter, setdatefilter] = useState('')
  const [customer_search, setcustomer_search] = useState('')
  const debouncedSearchTerm = useDebounce(search, 2000) // 2-second debounce
  const debouncedCustomerSearchTerm = useDebounce(customer_search, 2000) // 2-second debounce
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totaltickets, settotaltickets] = useState(1)
  const [customerpreview, setcustomerpreview] = useState(false)
  const [previewticket, setpreviewticket] = useState([])
  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange
  const [onfiltering, setonfiltering] = useState(false)
  // Retrieve user-related information
  const store_id = sessionStorage.getItem('store_id') ?? ''
  const cust_id = localStorage.getItem('chatlog_customer_id') ?? ''
  const chatLog = new GetChatLog()
  const { ripple, createRipple } = useRippleEffect()
  const { handleopenClosedropdown, isopenpopup } = FilterDropDownHook()
  const datepickerref = useRef(null)
  const [error, setError] = useState('')

  const closefilterpopup = () => {
    handleopenClosedropdown()
  }

  // useEffect(() => {
  //   const controller = new AbortController() // Create a new AbortController instance
  //   const { signal } = controller // Extract the signal
  //   const customer_chatlog = async storeId => {
  //     const customerlist = await chatLog.Get_Customer_List(storeId, signal)
  //     if (customerlist.success) {
  //       localStorage.setItem(
  //         'chatlog_customer_id',
  //         customerlist.data[0]?.customer_id
  //       )
  //       localStorage.setItem(
  //         'chatlog_customer_email',
  //         customerlist.data[0]?.email
  //       )
  //       localStorage.setItem('chatlog_customer_name', customerlist?.data[0]?.name ?? '')
  //       // console.log(customerlist.data[0])
  //       window.dispatchEvent(
  //         new CustomEvent('chatlog_customer_id', { detail: true })
  //       )
  //     } else {
  //       console.log(customerlist.success)
  //     }

  //     // const customerchatlist = await chatLog.Get_Chat_List();
  //     // const customerchatlog = await chatLog.Get_Chat_Log();
  //     // console.log(customerlist);
  //     // console.log(customerchatlist);
  //     // console.log(customerchatlog);
  //     // console.log(DateTimeFormat().time)
  //   }

  //   if (sessionStorage.getItem('store_id')) {
  //     customer_chatlog(sessionStorage.getItem('store_id'))
  //   }

  //   const handlecustomerlog = () => {
  //     if (sessionStorage.getItem('store_id')) {
  //       customer_chatlog(sessionStorage.getItem('store_id'))
  //     }
  //   }

  //   window.addEventListener('deletereload', handlecustomerlog())

  //   return () => {
  //     window.removeEventListener('deletereload', handlecustomerlog())
  //     controller.abort() // Abort the fetch request if the component is unmounted or if the effect cleanup happens
  //   }
  // }, [])

  // useEffect(() => {
  //   const controller = new AbortController() // Create a new AbortController instance
  //   const { signal } = controller // Extract the signal
  //   const checkCutomerId = async (cust_id, storeID) => {
  //     //   console.log(cust_id)
  //     const customerChatlist = await chatLog.Get_Chat_List(storeID, cust_id)

  //     if (customerChatlist.success) {
  //       if (customerChatlist.data.length > 0) {
  //         localStorage.setItem(
  //           'chatlist_customer_id',
  //           customerChatlist.data[0]?.chat_id
  //         )
  //         window.dispatchEvent(
  //           new CustomEvent('chatlist_customer_id', { detail: true })
  //         )
  //       }
  //     }
  //   }

  //   if (
  //     localStorage.getItem('chatlog_customer_id') &&
  //     sessionStorage.getItem('store_id')
  //   ) {
  //     checkCutomerId(
  //       localStorage.getItem('chatlog_customer_id'),
  //       sessionStorage.getItem('store_id')
  //     )
  //   }

  //   const handleGetNewCustomerID = event => {
  //     if (event.detail === true) {
  //       if (
  //         localStorage.getItem('chatlog_customer_id') &&
  //         sessionStorage.getItem('store_id')
  //       ) {
  //         checkCutomerId(
  //           localStorage.getItem('chatlog_customer_id'),
  //           sessionStorage.getItem('store_id')
  //         )
  //         window.dispatchEvent(
  //           new CustomEvent('loadchatlist', { detail: true })
  //         )
  //       }
  //       const ticketEmail = localStorage.getItem('chatlog_customer_email')
  //     }
  //   }

  //   window.addEventListener('deletereload', handleGetNewCustomerID)
  //   window.addEventListener('chatlog_customer_id', handleGetNewCustomerID)
  //   return () => {
  //     window.removeEventListener('chatlog_customer_id', handleGetNewCustomerID)
  //     window.removeEventListener('deletereload', handleGetNewCustomerID)
  //     // controller.abort(); // Abort the fetch request if the component is unmounted or if the effect cleanup happens
  //   }
  // }, [])

  // Common function to fetch tickets with dynamic filters
  const fetchTickets = async (params = {}) => {
    const {
      customerId = '',
      status = '',
      page = 1,
      searchTerm = '',
      ticketNumber = 0,
      sort = '',
      customer_search = '',
      created_at_from = '',
      created_at_to = ''
    } = params

    try {
      const response = await Get_tickets(
        store_id,
        customerId,
        '',
        status,
        page,
        searchTerm,
        ticketNumber,
        sort,
        customer_search,
        created_at_from,
        created_at_to
      )

      if (response.success) {
        console.log(response.data)
        setTickets(response.data)
        settotaltickets(response.pagination.total)
        setTotalPages(
          Math.ceil(response.pagination.total / response.pagination.limit)
        )
        // console.log(Math.ceil(response.pagination.total/response.pagination.limit))
      } else {
        setTickets([]) // Clear tickets if the request fails
      }
    } catch (error) {
      console.error('Error fetching tickets:', error)
    }
  }

  // Fetch all tickets on component mount
  useEffect(() => {
    fetchTickets()
  }, [])

  // Search tickets when the debounced search term changes
  useEffect(() => {
    if (debouncedSearchTerm.trim()) {
      let ticketNumber = 0
      let searchTerm = debouncedSearchTerm.trim()

      if (!isNaN(searchTerm) && Number.isInteger(Number(searchTerm))) {
        ticketNumber = Number(searchTerm)
        searchTerm = '' // Clear searchTerm if ticketNumber is set
      }

      fetchTickets({ searchTerm, ticketNumber })
    } else {
      if (onfiltering) {
        const [startDate, endDate] = dateRange

        // Prepare filter parameters
        const params = {
          // searchTerm,
          // ticketNumber,
          status: statusfilter || '',
          customer_search: customer_search || '',
          created_at_from: startDate
            ? format(new Date(startDate), 'yyyy-MM-dd')
            : '',
          created_at_to: endDate ? format(new Date(endDate), 'yyyy-MM-dd') : ''
        }
        const isFilterApplied = Object.values(params).some(
          value => value !== '' && value !== 0
        )

        fetchTickets(isFilterApplied ? params : {})
      } else {
        fetchTickets() // Fetch all tickets if search is cleared
      }
    }
  }, [debouncedSearchTerm])

  const handlefilterSubmit = () => {
    // let ticketNumber = 0
    // let searchTerm = search.trim() // Directly use `search` instead of `debouncedSearchTerm`

    // Check if searchTerm is a number (integer)
    // if (!isNaN(searchTerm) && Number.isInteger(Number(searchTerm))) {
    //   ticketNumber = Number(searchTerm)
    //   searchTerm = '' // Clear searchTerm if it's a number
    // }

    // Extract start and end dates from dateRange
    const [startDate, endDate] = dateRange
    console.log(dateRange,startDate)
  if(dateRange[0]!==null){
      if (!dateRange[0]) {
        setError('start dates required.')
        return
      }
  
      if (!dateRange[1]) {
        setError('end dates required.')
        return
      }
    }
    setError("")

    // Prepare filter parameters
    const params = {
      // searchTerm,
      // ticketNumber,
      status: statusfilter || '',
      customer_search: customer_search || '',
      created_at_from: startDate
        ? format(new Date(startDate), 'yyyy-MM-dd')
        : '',
      created_at_to: endDate ? format(new Date(endDate), 'yyyy-MM-dd') : ''
    }
    
   

    // Check if any filter is applied
    const isFilterApplied = Object.values(params).some(
      value => value !== '' && value !== 0
    )

    if (isFilterApplied) {
      handleopenClosedropdown()
    } else {
      handleopenClosedropdown()
    }
    // Update filtering state
    setonfiltering(isFilterApplied)

    // Fetch tickets
    fetchTickets(isFilterApplied ? params : {})
  }

  // Handle search button click
  const searchhandleclick = () => {
    let ticketNumber = 0
    let searchTerm = debouncedSearchTerm.trim()

    if (!isNaN(searchTerm) && Number.isInteger(Number(searchTerm))) {
      ticketNumber = Number(searchTerm)
      searchTerm = '' // Clear searchTerm if ticketNumber is set
    }

    fetchTickets({ searchTerm, ticketNumber })
  }

  const handleStatusChange = async (ticketid, ticketNumber, newStatus) => {
    // console.log(ticketid, ticketNumber, newStatus)
    setTickets(prevTickets =>
      prevTickets.map(ticket =>
        ticket.ticket_number === ticketNumber
          ? { ...ticket, status: newStatus }
          : ticket
      )
    )
    const ticket_update = await update_tickets(ticketid, newStatus)
    console.log(ticket_update)
    if(ticket_update.success){
      toast.success(`${ticket_update.message}`, {
        position: 'bottom-center'
      })
    }
    
  }

  // Filter button click handler
  const handleFilterClick = () => {
    // Filter tickets based on selected filters
    fetchTickets({
      status: statusfilter,
      sort: datefilter,
      searchTerm: debouncedSearchTerm,
      customer_search: debouncedCustomerSearchTerm,
      created_at_from: '',
      created_at_to: ''
    })
  }

  // Reset button click handler
  const handleResetClick = () => {
    // Clear filters and search
    setstatusfilter('')
    setdatefilter('')
    // setSearch('')
    // fetchTickets() // Fetch all tickets again
    setcustomer_search('')
    setDateRange([null, null])
  }

  useEffect(() => {
    console.log(currentPage)
    fetchTickets({ page: currentPage })
  }, [currentPage])

  const handlePageChange = direction => {
    console.log(direction)
    setCurrentPage(prevPage => {
      if (direction === 'next' && prevPage < totalPages) {
        return prevPage + 1
      } else if (direction === 'prev' && prevPage > 1) {
        return prevPage - 1
      }
      return prevPage
    })
  }

  // Use the total count from the backend or data.length for the current page
  const startItem = (currentPage - 1) * 10 + 1
  const endItem = startItem + tickets.length - 1

  const handleopenpreview = (chat_id, ticket) => {
    console.log(chat_id, ticket)
    setcustomerpreview(true)
    setpreviewticket(ticket)
    localStorage.setItem('chatlist_customer_id', chat_id)
    // window.dispatchEvent(
    //   new CustomEvent('chatlog_customer_id', { detail: true })
    // )
    // window.dispatchEvent(
    //   new CustomEvent('chatlist_customer_id', { detail: true })
    // )
  }

  const ticket_delete = async id => {
    const delete_response = await delete_tickets(id)
    if (delete_response.success) {
      toast.success(`${delete_response.message}`, {
        position: 'bottom-center'
      })
      fetchTickets()
    } else {
      toast.error(`${delete_response.message}`, {
        position: 'bottom-center'
      })
    }
  }

  return (
    <>
      {customerpreview && (
        <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[90] p-5'>
          <div
            className='bg-white p-5 sm:p-7 shadow-lg w-full max-w-[1200px] mx-auto my-[30px] rounded-[24px] border border-white overflow-y-auto min-h-[40%] max-h-[90%] scrollbar-hide'
            style={{ boxShadow: '-1px 0px 0px 0px #DFE5F1 inset' }}
          >
            {/* Close Button */}
            <div className='flex justify-between'>
              <h2 className='font-extrabold text-[25px] mb-10'>
                Ticket Information
              </h2>
              <button
                className='w-[30px] sm:w-[40px] h-[30px] sm:h-[40px] text-[14px] sm:text-[18px] cursor-pointer'
                aria-label='Close'
                onClick={() => setcustomerpreview(false)}
              >
                <svg
                  width='30'
                  height='30'
                  viewBox='0 0 30 30'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M9.10041 7.77459C8.7343 7.40847 8.1407 7.40847 7.77459 7.77459C7.40847 8.1407 7.40847 8.7343 7.77459 9.10041L13.6742 15L7.77459 20.8996C7.40847 21.2657 7.40847 21.8593 7.77459 22.2254C8.1407 22.5915 8.7343 22.5915 9.10041 22.2254L15 16.3258L20.8996 22.2254C21.2657 22.5915 21.8593 22.5915 22.2254 22.2254C22.5915 21.8593 22.5915 21.2657 22.2254 20.8996L16.3258 15L22.2254 9.10041C22.5915 8.7343 22.5915 8.1407 22.2254 7.77459C21.8593 7.40847 21.2657 7.40847 20.8996 7.77459L15 13.6742L9.10041 7.77459Z'
                    fill='#373737'
                  />
                </svg>
              </button>
            </div>

            {/* Content */}
            <div className='flex flex-col sm:flex-row gap-4'>
              {/* Left Container */}
              <div className='flex-1 p-4 sm:border-r border-gray-300'>
                <h2 className='text-lg font-bold mb-4'>Customer Details</h2>

                <div className='mb-4'>
                  <label className='block font-semibold mb-1'>
                    Customer ID:
                  </label>
                  <p className='text-[14px]'>{previewticket.customer_id}</p>
                </div>

                <div className='mb-4'>
                  <label className='block font-semibold mb-1'>Name:</label>
                  <p className='text-[14px]'>
                    {previewticket.ChatUsers[0]?.name || 'N/A'}
                  </p>
                </div>

                <div className='mb-4'>
                  <label className='block font-semibold mb-1'>Email:</label>
                  <p className='text-[14px]'>
                    {previewticket.ChatUsers[0]?.email || 'N/A'}
                  </p>
                </div>

                <div className='mb-4'>
                  <label className='block font-semibold mb-1'>Message:</label>
                  <p className='text-[14px]'>{previewticket.content}</p>
                </div>

                <div className='mb-4'>
                  <label className='block font-semibold mb-1'>Status:</label>
                  <p className='text-[14px]'>{previewticket.status}</p>
                </div>
              </div>

              {/* Right Container */}
              <div className='flex-1 p-4'>
                <h2 className='text-lg font-bold mb-4'>Conversation</h2>

                <div className='flex-1 w-full'>
                  <Conversation
                    chatlistId={localStorage.getItem('chatlist_customer_id')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className='relative py-3 px-3'>
        <div className='flex-1 h-auto bg-white mt-[15px] rounded-[16px] shadow-md'>
          <div className='p-4'>
            <div className='mb-5 flex justify-between'>
              <h1 class='text-[#252530] font-extrabold text-[23px] '>
                Ticket Management
              </h1>{' '}
              <div className='flex gap-2'>
                {/* <TrainFilterDropdownPopup/> */}
                {/* <button
                  className='mt-4 md:mt-0 px-4 py-2 bg-[#000000] w-full md:w-[140px] flex justify-center items-center text-white rounded-full text-[14px] '
                  onClick={handleFilterClick}
                >
                  <p className='text-[11px] mt-1 mr-2 font-[700]'> </p>
                  <p className='mr-2 text-[12px] font-[700] leading-5'>
                    {' '}
                    Filter{' '}
                  </p>
                </button> */}
                <div className='relative'>
                  <div className='relative w-full '>
                    <button
                      onClick={event => {
                        createRipple(event)
                        handleopenClosedropdown()
                      }}
                      className={`relative bg-[#f4f4f4] py-[11px] px-[11px] rounded-[8px] border-[0.5px] cursor-pointer 
      hover:bg-gray-200 transition-all  ${isopenpopup && 'bg-gray-200'}`}
                    >
                      <TfiFilter size={16} />
                      {ripple.map(({ x, y, id }) => (
                        <span
                          key={id}
                          className='absolute block bg-white opacity-100 rounded-full animate-ripple'
                          style={{
                            top: y,
                            left: x,
                            width: '20px',
                            height: '20px',
                            transform: 'translate(50%, 50%)'
                          }}
                        ></span>
                      ))}
                    </button>
                    {onfiltering && (
                      <span className=' absolute right-[0.1px] top-[-1px] bg-green-400 w-2 h-2 rounded'></span>
                    )}
                  </div>
                  {isopenpopup && (
                    <TrainFilterDropdownPopup
                      isopenpopup={closefilterpopup}
                      clearbtn={handleResetClick}
                      // clearbtn={handleclearfilter}
                      fields={[
                        // Custom Dropdown
                        <div className='flex relative w-full'>
                          <div className='flex flex-col w-full justify-start'>
                            <p className='text-[#7A7D9C] text-[14px] mb-1'>
                              Status
                            </p>
                            <select
                              name='status'
                              value={statusfilter}
                              onChange={e => setstatusfilter(e.target.value)}
                              className='w-full cursor-pointer border-[1px] border-[#BFC4D7] py-2 pl-3 pr-9 rounded-[6px] text-sm focus:outline-none appearance-none '
                            >
                              <option value=''>---</option>
                              <option value='open'>Open</option>
                              <option value='inprogress'>In Progress</option>
                              <option value='resolved'>Resolved</option>
                              <option value='closed'>Closed</option>
                            </select>
                            <IonIcon
                              name='chevron-down'
                              className='absolute right-2 top-[58%] text-gray-500 cursor-pointer pointer-events-none'
                            />
                          </div>
                        </div>,
                        // Custom Date Picker
                        <div className='relative flex cursor-pointer w-full'>
                          <div className='flex flex-col w-full justify-start'>
                            <p className='text-[#7A7D9C] text-[14px] mb-1'>
                              Date Range
                            </p>
                            <DatePicker
                              ref={datepickerref}
                              selected={startDate}
                              onChange={update => setDateRange(update)}
                              startDate={startDate}
                              endDate={endDate}
                              selectsRange
                              dateFormat='yyyy-MM-dd'
                              autoComplete='off'
                              name='daterange'
                              className='w-full border-[1px] border-[#BFC4D7] placeholder:text-[#ABADB5] py-2 pl-3 pr-9 rounded-[6px] text-sm focus:outline-none'
                            />
                            {error && (
                          <p className='text-red-500 text-sm absolute bottom-[-18px]'>{error}</p>
                        )}
                          </div>
                          <IonIcon
                            name='calendar-outline'
                            className='absolute right-2 top-[58%] text-gray-500 cursor-pointer '
                            onClick={() => datepickerref.current.setOpen(true)} // Open DatePicker programmatically
                          />
                        </div>,
                        <div className='flex relative w-full'>
                          <div className='flex flex-col w-full justify-start'>
                            <p className='text-[#7A7D9C] text-[14px] mb-1'>
                              Customer Name or Email
                            </p>
                            <input
                              type='text'
                              // placeholder='Search by customer name or email'
                              value={customer_search}
                              onChange={e => setcustomer_search(e.target.value)}
                              className='w-full  border-[1px] border-[#BFC4D7] py-2 pl-3 pr-9 rounded-[6px] text-sm focus:outline-none appearance-none '
                            />
                          </div>
                        </div>
                      ]}
                      onsubmit={handlefilterSubmit}
                    />
                  )}
                </div>
                <div className='flex relative w-full'>
                  <input
                    type='text'
                    placeholder='Search by ticket.no or Message'
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    className='focus:outline-none w-[254px]  h-[41px] rounded-[39px] gap-[129px] border-[0.5px]  pl-[30px] pt-[10px] pr-[40px] pb-[12px] bg-[#F4F4F4] placeholder:text-[#565656] placeholder:text-[12px] text-[14px]'
                  />
                  <svg
                    width='16'
                    height='15'
                    className='absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer'
                    viewBox='0 0 16 15'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M15.0555 13.3145L11.7477 10.0066C12.544 8.94645 12.9739 7.65598 12.9725 6.33C12.9725 2.94305 10.217 0.1875 6.83 0.1875C3.44305 0.1875 0.6875 2.94305 0.6875 6.33C0.6875 9.71695 3.44305 12.4725 6.83 12.4725C8.15598 12.4739 9.44645 12.044 10.5066 11.2477L13.8145 14.5555C13.9819 14.7052 14.2003 14.7851 14.4248 14.7788C14.6493 14.7725 14.8629 14.6805 15.0217 14.5217C15.1805 14.3629 15.2725 14.1493 15.2788 13.9248C15.2851 13.7003 15.2052 13.4819 15.0555 13.3145ZM2.4425 6.33C2.4425 5.46223 2.69982 4.61396 3.18193 3.89244C3.66403 3.17092 4.34927 2.60856 5.15098 2.27648C5.95269 1.9444 6.83487 1.85751 7.68596 2.0268C8.53705 2.1961 9.31883 2.61397 9.93243 3.22757C10.546 3.84117 10.9639 4.62295 11.1332 5.47404C11.3025 6.32513 11.2156 7.20731 10.8835 8.00902C10.5514 8.81073 9.98909 9.49597 9.26756 9.97807C8.54604 10.4602 7.69777 10.7175 6.83 10.7175C5.66679 10.7161 4.55162 10.2534 3.72911 9.43089C2.9066 8.60838 2.4439 7.49321 2.4425 6.33Z'
                      fill='#0F062C'
                      fillOpacity='0.7'
                    />
                  </svg>
                  {/* <IonIcon
                      name='search-outline'
                      className='absolute right-3 top-[29%] text-gray-500 cursor-pointer '
                      /> */}
                </div>
                {/* <button
                  className='mt-4 md:mt-0 px-4 py-2 bg-red-600 w-full md:w-[140px] flex justify-center items-center text-white rounded-full text-[14px] '
                  onClick={()=>{
                    handleResetClick()
                    fetchTickets()
                    setonfiltering(false)
                  }}
                >
                  <p className='text-[11px] mt-1 mr-2 font-[700]'> </p>
                  <p className='mr-2 text-[12px] font-[700] leading-5'>
                    {' '}
                    Reset{' '}
                  </p>
                </button> */}
              </div>
            </div>

            <div className='w-full'>
              <div className='overflow-x-auto w-full'>
                <table className='min-w-full table-auto w-full'>
                  <thead>
                    <tr className='border-b-[1px]'>
                      <th className='border-none p-3 text-left font-extrabold text-[14px] leading-[24px]'>
                        Ticket #
                      </th>
                      <th className='border-none p-3 text-left font-extrabold text-[14px] leading-[24px]'>
                        Customer
                      </th>
                      <th className='border-none p-3 text-left font-extrabold text-[14px] leading-[24px]'>
                        Status
                      </th>
                      <th className='border-none p-3 text-left font-extrabold text-[14px] leading-[24px] '>
                        Message
                      </th>
                      <th className='border-none p-3 text-left font-extrabold text-[14px] leading-[24px]'>
                        Created Date
                      </th>
                      <th className='border-none p-3 text-left font-extrabold text-[14px] leading-[24px]'>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tickets.length === 0 ? (
                      <tr>
                        <td colSpan='6' className='text-center py-4'>
                          No data available
                        </td>
                      </tr>
                    ) : (
                      tickets.map(tickets => {
                        return (
                          <tr className='border-b-[1px]'>
                            <td className='border-none p-3 font-[500] text-[11.5px] text-[#252C32]'>
                              {tickets.ticket_number}
                            </td>
                            <td className='border-none p-3 font-[500] text-[11.5px] text-[#252C32]'>
                              {tickets.ChatUsers[0].name} <br />
                              <span className='text-sm text-gray-500'>
                                {tickets.ChatUsers[0].email}
                              </span>
                            </td>
                            <td className='border-none p-3 font-[500] text-[11.5px] text-[#252C32]'>
                              <select
                                value={tickets.status}
                                onChange={e =>
                                  handleStatusChange(
                                    tickets.ticket_id,
                                    tickets.ticket_number,
                                    e.target.value
                                  )
                                }
                                className='border rounded-md px-2 py-1 w-auto'
                              >
                                <option value='open'>Open</option>
                                <option value='inprogress'>In Progress</option>
                                <option value='resolved'>Resolved</option>
                                <option value='closed'>Closed</option>
                              </select>
                            </td>
                            <td className='border-none p-3 font-[500] text-[11.5px] text-[#252C32] '>
                              <div className='max-w-[290px] break-words whitespace-normal'>
                                {tickets.content}
                              </div>
                            </td>

                            <td className='border-none p-3 font-[500] text-[11.5px] text-[#252C32]'>
                              {new Date(tickets.createdAt).toLocaleString(
                                'en-US',
                                {
                                  timeZone: 'UTC'
                                }
                              )}
                            </td>
                            <td className='border-none   p-3 font-[500] text-[14.5px] cursor-pointer text-[#252C32] text-center'>
                              <div className='flex gap-4 items-center'>
                                <IonIcon
                                  name='eye-outline'
                                  onClick={() =>
                                    handleopenpreview(tickets.chat_id, tickets)
                                  }
                                />
                                <IonIcon
                                  name='trash-outline'
                                  className='hover:text-red-600'
                                  onClick={() =>
                                    ticket_delete(tickets.ticket_id)
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                        )
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className=''>
              <div className='flex justify-between items-center p-3'>
                <span className='font-[600] text-[12px] '>
                  {startItem} - {endItem} of {totaltickets}
                </span>

                <div className='flex items-center'>
                  <button
                    onClick={() => handlePageChange('prev')}
                    disabled={currentPage === 1}
                    className=' p-2 '
                  >
                    &lt; {/* Left Arrow */}
                  </button>
                  <span className='mx-2 font-[600] text-[12px]'>
                    Page {currentPage} of {totalPages}
                  </span>
                  <button
                    onClick={() => handlePageChange('next')}
                    disabled={currentPage === totalPages}
                    className='p-2'
                  >
                    &gt; {/* Right Arrow */}
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="overflow-x-auto">
            <table className="table-auto w-full border-collapse border border-gray-200">
              <thead>
                <tr className="bg-gray-100">
                  <th className="border border-gray-300 px-4 py-2 text-left">Ticket #</th>
                  <th className="border border-gray-300 px-4 py-2 text-left">Customer</th>
                  <th className="border border-gray-300 px-4 py-2 text-left">Status</th>
                  <th className="border border-gray-300 px-4 py-2 text-left">Message</th>
                  <th className="border border-gray-300 px-4 py-2 text-left">Created Date</th>
                </tr>
              </thead>
              <tbody>
                {filteredTickets.length > 0 ? (
                  filteredTickets.map((ticket) => (
                    <tr key={ticket.ticketNumber}>
                      <td className="border border-gray-300 px-4 py-2">{ticket.ticketNumber}</td>
                      <td className="border border-gray-300 px-4 py-2">
                        {ticket.customer.name} <br />
                        <span className="text-sm text-gray-500">{ticket.customer.email}</span>
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        <select
                          value={ticket.status}
                          onChange={(e) => handleStatusChange(ticket.ticketNumber, e.target.value)}
                          className="border rounded-md px-2 py-1"
                        >
                          <option value="Open">Open</option>
                          <option value="In Progress">In Progress</option>
                          <option value="Resolved">Resolved</option>
                          <option value="Closed">Closed</option>
                        </select>
                      </td>
                      <td className="border border-gray-300 px-4 py-2">{ticket.content}</td>
                      <td className="border border-gray-300 px-4 py-2">{ticket.createdDate}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="border border-gray-300 px-4 py-2 text-center" colSpan="5">
                      No tickets found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div> */}
          </div>
        </div>
      </div>
      <ToastContainer/>
    </>
  )
}

export default TicketManagement
