import { useState, useEffect } from "react";

const useRippleEffect = () => {
  const [ripple, setRipple] = useState([]);

  useEffect(() => {
    if (ripple.length > 0) {
      const timer = setTimeout(() => setRipple([]), 600);
      return () => clearTimeout(timer);
    }
  }, [ripple]);

  const createRipple = (event) => {
    const { clientX, clientY, target } = event;
    const { left, top } = target.getBoundingClientRect();
    const x = clientX - left;
    const y = clientY - top;

    setRipple([{ x, y, id: Date.now() }]);
  };

  return { ripple, createRipple };
};

export default useRippleEffect;
