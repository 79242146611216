import { useEffect, useState } from 'react'
import { GetChatLog } from '../../services/data'
import DateTimeFormat from '../../util/DateTimeformat'

const Sidebar = () => {
  const [CustomerList, SetCustomerList] = useState([])
  const [Initialactive, SetInitialactive] = useState('')
  const chatLog = new GetChatLog()
  // console.log(chatLog.Get_Customer_List());

  // Check if a hex color is dark
  const isColorDark = color => {
    const hex = color.replace('#', '')
    const r = parseInt(hex.substring(0, 2), 16)
    const g = parseInt(hex.substring(2, 4), 16)
    const b = parseInt(hex.substring(4, 6), 16)
    const brightness = (r * 299 + g * 587 + b * 114) / 1000
    return brightness < 128
  }

  // Lighten color if it’s dark
  const lightenColor = (color, percent = 0.5) => {
    const num = parseInt(color.replace('#', ''), 16)
    const amt = Math.round(2.55 * percent * 100)
    const r = (num >> 16) + amt
    const g = ((num >> 8) & 0x00ff) + amt
    const b = (num & 0x0000ff) + amt
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`
  }

  // Generate or retrieve color for customer
  const getColorForCustomer = customerId => {
    const storedColor = localStorage.getItem(`color_${customerId}`)
    if (storedColor) {
      return storedColor
    } else {
      let randomColor = getRandomColor()
      if (isColorDark(randomColor)) {
        randomColor = lightenColor(randomColor)
      }
      localStorage.setItem(`color_${customerId}`, randomColor)
      return randomColor
    }
  }

  // Generate random hex color
  const getRandomColor = () => {
    const letters = '0123456789ABCDEF'
    let color = '#'
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)]
    }
    return color
  }

  useEffect(() => {
    const controller = new AbortController();  // Create a new AbortController instance
    const { signal } = controller; // Extract the signal
    const customer_chatlog = async storeId => {
      const customerlist = await chatLog.Get_Customer_List(storeId,signal)
      if (customerlist.success) {
        SetCustomerList(customerlist.data)
        SetInitialactive(customerlist?.data[0]?.customer_id)
        localStorage.setItem(
          'chatlog_customer_id',
          customerlist.data[0].customer_id
        )
        localStorage.setItem(
          'chatlog_customer_email',
          customerlist.data[0].email
        )
        localStorage.setItem('chatlog_customer_name', customerlist.data[0].name)
        // console.log(customerlist.data[0])
        window.dispatchEvent(
          new CustomEvent('chatlog_customer_id', { detail: true })
        )
      } else {
        console.log(customerlist.success)
        SetCustomerList([])
      }

      // const customerchatlist = await chatLog.Get_Chat_List();
      // const customerchatlog = await chatLog.Get_Chat_Log();
      // console.log(customerlist);
      // console.log(customerchatlist);
      // console.log(customerchatlog);
      // console.log(DateTimeFormat().time)
    }

    if (sessionStorage.getItem('store_id')) {
      customer_chatlog(sessionStorage.getItem('store_id'))
    }

    const handlecustomerlog = () => {
      if (sessionStorage.getItem('store_id')) {
        customer_chatlog(sessionStorage.getItem('store_id'))
      }
    }

    window.addEventListener('deletereload', handlecustomerlog())

    return () => {
      window.removeEventListener('deletereload', handlecustomerlog())
      controller.abort(); // Abort the fetch request if the component is unmounted or if the effect cleanup happens
    }
  }, [])

  // useEffect(() => {
  //     CustomerList.map((item, index) => {
  //         console.log(item.customer_id, index)
  //     })
  // }, [CustomerList])

  const handleActiveCustomerid = id => {
    localStorage.setItem('chatlog_customer_id', id)
    localStorage.setItem(
      'chatlog_customer_email',
      CustomerList.find(customer => customer.customer_id === id).email
    )
    localStorage.setItem(
      'chatlog_customer_name',
      CustomerList.find(customer => customer.customer_id === id).name
    )
    window.dispatchEvent(
      new CustomEvent('chatlog_customer_id', { detail: true })
    )
    SetInitialactive(id)
  }

  return (
    <div className=' pt-11 pr-7'>
      <h1 className='font-extrabold text-[23px] leading-[48px]'>Chats</h1>
      <div className='block mt-5 space-y-3'>
        {/* <div className="flex justify-center items-center">
                    <button className="bg-[#7C50DD] text-white w-[99px] h-[33px] gap-10 pt-[4px] pb-[4px] pr-[10px] pl-[10px] rounded-[40px] shadow-[0_0_4px_rgba(0,0,0,0.15)] text-[10px] font-bold leading-[13.66px]">History</button>
                </div> */}

        {/* customer lists */}

        <div className='mt-10 '>
          <div className='flex flex-col mt-6 gap-5 mb-9 max-h-[270px] scrollbar-custom1 overflow-x-hidden'>
            {CustomerList.map((item, index) => {
              const customerColor = getColorForCustomer(item.customer_id)
              return (
                <div
                  key={index}
                  className={`h-[54px]  hover:bg-white rounded-[17px] cursor-pointer px-[11px]  py-[7px] flex items-center justify-between gap-2 whitespace-nowrap  ${
                    Initialactive === item.customer_id && 'bg-white'
                  }`}
                  onClick={() =>
                    handleActiveCustomerid(
                      item.customer_id,
                      item.name,
                      item.email
                    )
                  }
                >
                  <div className='flex items-center gap-3'>
                    {item.name !== null ? (
                      <div
                        className='w-8 h-8 rounded-full flex items-center justify-center'
                        style={{ backgroundColor: customerColor }}
                      >
                        {item.name.charAt(0).toUpperCase()}
                      </div>
                    ) : (
                      <div
                        className='w-8 h-8 rounded-full flex items-center justify-center'
                        style={{ backgroundColor: customerColor }}
                      >
                        {item.customer_id.charAt(0).toUpperCase()}
                      </div>
                    )}
                    {/* <div
                                                className="w-8 h-8 rounded-full flex items-center justify-center"
                                                style={{ backgroundColor: customerColor }}
                                            >
                                                {item.customer_id.charAt(0).toUpperCase()}
                                            </div> */}
                    <div className='flex flex-col gap-2 '>
                      {item.name !== null ? (
                        <p className='font-semibold text-[13px] text-[#444444] leading-[17.76px] w-[190px] overflow-hidden truncate'>
                          {item.name}
                        </p>
                      ) : (
                        <p className='font-semibold text-[13px] text-[#444444] leading-[17.76px] w-[190px] overflow-hidden truncate'>
                          {item.customer_id}
                        </p>
                      )}
                      {/* <p className="font-semibold text-[13px] text-[#444444] leading-[17.76px] w-[100px] overflow-hidden truncate">
                                                    {item.name}
                                                </p> */}
                      <div className='flex gap-2'>
                        <p className='font-normal text-[11px] text-[#444444] leading-[15.03px]'>{`${
                          DateTimeFormat(item.last_message_time).date
                        }`}</p>
                        <p className='font-normal text-[11px] text-[#444444] leading-[15.03px] '>{`${
                          DateTimeFormat(item.last_message_time).time
                        }`}</p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="flex self-start ">
                                            <p className="font-medium text-[13px] leading-[17.76px] text-[#4F5257] mr-2">{`${DateTimeFormat(item.last_message_time).time}`}</p>
                                        </div> */}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
